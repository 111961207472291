import React, { useEffect } from "react";
import Banner from "../../components/header/Banner";
import Default from "../../components/default/Default";

const HowItWorkPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Default>
      <section>
        <Banner title="How It Works"  />
      </section>

      <section>
        <div className="container gap-40 mobile-gap mb-5">
          <div className="row">
            <div className="offset-md-2 col-md-8">
              <div className="f-32 fw-600 c-orange">How It Works</div>
              <div className="f-20 fw-600 c-orange mt-4 mobile-mt-2">
                Making Money :
              </div>

              <ol className="mt-2 fw-400 c-gray ">
                <li>
                  List Your Item: Post your unused possession on Rentoho for
                  rent.
                </li>
                <li className="mt-2">
                  Set Rental Price: Choose your rental rate (e.g., per day or
                  per period).
                </li>
                <li className="mt-2">
                  Security Deposit: The renter pays a security deposit to
                  Rentoho to ensure item safety.
                </li>
                <li className="mt-2">
                  Select Payment Method: Both the owner and renter choose their
                  preferred payment and payout methods.
                </li>
                <li className="mt-2">
                  Exchange Confirmation: Both the owner and renter must confirm
                  the exchange of the item in the app for the rental to be
                  activated.
                </li>
                <li className="mt-2">
                  Item Return: When the rental period ends, the renter returns
                  the item, and both parties confirm the return in the app.
                </li>
                <li className="mt-2">
                  Release of Funds: After both confirmations, Rentoho calculates
                  the payout and transfers the rental payment to the owner.
                </li>
                <li className="mt-2">
                  Deposit Refund: If the owner confirms that the item was
                  returned in good condition, the renter receives their security
                  deposit back. If the item is not returned in good condition,
                  the owner receives the security deposit.
                </li>
              </ol>

              <div className="f-20 fw-600 c-orange mt-4">Saving Money :</div>

              <ol className="mt-2 fw-400 c-gray ">
                <li>
                  Find What You Need: Search for items you need temporarily,
                  like furniture or power tools.
                </li>
                <li className="mt-2">
                  Security Deposit: Pay a security deposit to ensure the safe
                  return of the item.
                </li>
                <li className="mt-2">
                  Select Payment Method: Choose your preferred payment method.
                </li>
                <li className="mt-2">
                  Exchange Confirmation: Both the renter and owner must confirm
                  the exchange in the app for the rental cost to start being
                  calculated.
                </li>
                <li className="mt-2">
                  Use the Item: Enjoy the rented item for the agreed period.
                </li>
                <li className="mt-2">
                  Return the Item: Return the item to the owner by the due date.
                </li>
                <li className="mt-2">
                  Confirm Return: Both parties confirm the item’s return in the
                  app— the owner confirms receiving it back, and the renter
                  confirms returning it.
                </li>
                <li className="mt-2">
                  Cost and Payout Calculation: Rentoho calculates the rental
                  cost and the owner’s payout after these confirmations.
                </li>
                <li className="mt-2">
                  Deposit Refund: If the owner confirms that the item was
                  returned in good condition, the renter receives their security
                  deposit back. If the item is not returned in good condition,
                  the owner receives the security deposit.
                </li>
                <li className="mt-2">
                  Everyone Wins: The renter saves money on big purchases, and
                  the owner earns from unused items.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default HowItWorkPage;
