import React, { useEffect, useState } from "react";
import ProductItem from "../products/productItem/ProductItem";
import PostRequest from "../../services/PostRequest";

const HomeGalleryAds = () => {
  const [promoteAds, setPromoteAds] = useState([]);

  const loadPromoteAd = async () => {
    try {
      const data = {
        place: "Homepage Gallery",
      };

      const response = await PostRequest("/ads/promote", data);

      setPromoteAds(response);
    } catch (error) {}
  };

  useEffect(() => {
    loadPromoteAd();
  }, []);

  if (promoteAds.length == 0) {
    return null;
  }

  return (
    <div className="container mt-4">
      <h3 className="mt-4 c-orange f-600">Most Recommended ads</h3>

      <div className="row mt-3">
        {promoteAds.map((item, index) => {
          return (
            <div key={index} className="col-md-3 mb-4">
              <ProductItem product={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeGalleryAds;
