import React, { useEffect } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import MyProductItems from "../../../components/products/myads/MyProductItems";

import "./myads.css";

const MyAds = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DefaultDashboard title="My Ads">
      <div className="bg-user-post-ad p-3">
        <div className="row">
          <MyProductItems />
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default MyAds;
