import React from "react";
import Banner from "../../components/header/Banner";
import "./login.css";

import RentohoFeauterText from "../../components/text/RentohoFeauterText";
import LoginFormComponent from "../../components/LoginFormComponent";
import Default from "../../components/default/Default";

const Login = () => {
  return (
    <Default>
      <section>
        <Banner title="Sign In" />
      </section>

      <section>
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 hidden-mobile">
                <RentohoFeauterText />
              </div>
              <div className="col-md-6">
                <div className="login-bg">
                  <LoginFormComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default Login;
