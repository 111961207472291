import React from "react";
import { SlLock } from "react-icons/sl";
import "./lock.css"
import { NavLink } from "react-router-dom";
const Lock = () => {
  return (
    <div className="lock-module">
      <SlLock size={160} className="c-orange" />

      <p className="my-4 fw-700">
        This features is locked for you! To unlock this feature you need to 
        verify your  profile! <br /> Click on below button to start verification now.
      </p>

      <NavLink to='/user/profile/verify'>
      <button className="my-button">Verify Now</button>
      </NavLink>
    </div>
  );
};

export default Lock;
