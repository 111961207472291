import { Fragment } from "react";
import PlaceholderLoading from "react-placeholder-loading";

export const AdsItemLoader = (props) => {
  return (
    <Fragment>
      {Array.from({ length: 3 }).map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="d-flex align-items-center">
                <PlaceholderLoading shape="rect" width={80} height={80} />
                <div className="ms-2">
                  <PlaceholderLoading shape="rect" width={300} height={12} />
                </div>
              </div>
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={12} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={12} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={12} />
            </td>
            <td>
              <div className="d-flex align-items-center">
                <PlaceholderLoading shape="rect" width={85} height={40} />
                <div className="ms-2">
                  <PlaceholderLoading shape="rect" width={40} height={40} />
                </div>
              </div>
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
};

export const AdsItemCardLoader = () => {
  return (
    <div className="row">
      {Array.from({ length: 9 }).map((list, index) => {
        return (
          <div key={index} className="col-md-4 mb-4">
            <div>
              <PlaceholderLoading shape="rect" width="100%" height={200} />

              <div className="mt-1">
                <PlaceholderLoading shape="rect" width={120} height={17} />
              </div>

              <div className="mt-1">
                <PlaceholderLoading shape="rect" width="100%" height={17} />
              </div>

              <div className="d-flex justify-content-between mt-1">
                <PlaceholderLoading shape="rect" width={100} height={17} />
                <PlaceholderLoading shape="rect" width={100} height={17} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const ProductDetailLoader = () => {
  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-md-8">
          <div className="c-black f-32 fw-600">
            <PlaceholderLoading shape="rect" width={350} height={38} />
          </div>

          <div className="mt-2">
            <span>
              <PlaceholderLoading shape="rect" width={130} height={30} />
            </span>
            <span className="ms-3">
              <PlaceholderLoading shape="rect" width={130} height={30} />
            </span>
          </div>

          <div className="mt-4">
            <PlaceholderLoading shape="rect" width="100%" height={500} />
          </div>

          <div className="mt-4">
            <PlaceholderLoading shape="rect" width={130} height={38} />
          </div>
        </div>
        <div className="col-md-4">
          <div className="product-saidbar ">
            <div className="padd-said-15 mt-1 d-flex justify-content-between align-items-center">
              <div>
                {" "}
                <PlaceholderLoading shape="rect" width={130} height={45} />
              </div>
              <div>
                <PlaceholderLoading shape="rect" width={60} height={45} />
              </div>
            </div>

            <div className="boder-saidbar-pd mt-1"></div>

            <div className="mt-1 padd-said-15">
              <PlaceholderLoading shape="rect" width="100%" height={48} />

              <div className="mt-3">
                <PlaceholderLoading shape="rect" width="100%" height={48} />
              </div>
            </div>

            <div className="boder-saidbar-pd mt-2"></div>

            <div className="mt-1 padd-said-15 ">
              <div className="d-flex justify-content-between align-items-center">
                <PlaceholderLoading shape="rect" width={130} height={70} />

                <PlaceholderLoading shape="rect" width={100} height={30} />
              </div>

              <div className="mt-4 cursor">
                <PlaceholderLoading shape="rect" width="100%" height={30} />

                <div className="mt-2">
                  <PlaceholderLoading shape="rect" width="100%" height={30} />
                </div>
              </div>
            </div>
            <div className="boder-saidbar-pd mt-2"></div>

            <div className="mt-1 padd-said-15">
              <PlaceholderLoading shape="rect" width={130} height={40} />
              <div className="mt-3">
                <PlaceholderLoading shape="rect" width="100%" height={300} />
              </div>
            </div>
          </div>

          <div className="mt-4 product-saidbar">
            <div className="padd-said-15">
              <PlaceholderLoading shape="rect" width={130} height={40} />

              <div className="mt-3 d-flex justify-content-between align-items-center">
                <div className=" f-16 fw-400">
                  <div>
                    <PlaceholderLoading shape="rect" width={100} height={25} />
                  </div>
                  <div className="mt-2">
                    <PlaceholderLoading shape="rect" width={100} height={25} />
                  </div>
                </div>

                <div className=" f-16 fw-400 c-gray">
                  <div>
                    <PlaceholderLoading shape="rect" width={40} height={25} />
                  </div>
                  <div className="mt-2">
                    <PlaceholderLoading shape="rect" width={40} height={25} />
                  </div>
                </div>
              </div>
            </div>
            <div className="boder-saidbar-pd mt-2"></div>

            <div className="p-3">
              <PlaceholderLoading shape="rect" width={160} height={35} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PaymentFormLoader = () => {
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <PlaceholderLoading shape="rect" width="100%" height={52} />
        </div>
        <div className="col-md-6">
          <PlaceholderLoading shape="rect" width="100%" height={52} />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-md-6">
          <PlaceholderLoading shape="rect" width="100%" height={52} />
        </div>
        <div className="col-md-3">
          <PlaceholderLoading shape="rect" width="100%" height={52} />
        </div>
        <div className="col-md-3">
          <PlaceholderLoading shape="rect" width="100%" height={52} />
        </div>
      </div>

      <div className="mt-4">
        <PlaceholderLoading shape="rect" width="100%" height={52} />
      </div>
    </div>
  );
};

export const MyOrdersLoader = () => {
  return (
    <Fragment>
      {Array.from({ length: 3 }).map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="d-flex align-items-center">
                <PlaceholderLoading shape="rect" width={80} height={80} />
                <div className="ms-2">
                  <PlaceholderLoading shape="rect" width={160} height={18} />
                  <div className="mt-2">
                    <PlaceholderLoading shape="rect" width={160} height={35} />
                  </div>
                </div>
              </div>
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={18} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={18} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={18} />
            </td>
            <td>
              <div className="d-flex align-items-center">
                <PlaceholderLoading shape="rect" width={100} height={38} />
              </div>
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
};

export const UserOrderDetailsLoader = () => {
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="bg-user-post-ad p-3">
          <div>
            <PlaceholderLoading shape="rect" width={200} height={32} />
          </div>

          <div className="mt-4 d-flex">
            <div>
              <PlaceholderLoading shape="rect" width={288} height={50} />

              <div className="gap-150">
                <PlaceholderLoading shape="rect" width={288} height={100} />
              </div>
            </div>

            <div className="ms-2">
              <PlaceholderLoading shape="rect" width={10} height="100%" />
            </div>
            <div className="ms-2">
              <div className="gap-100">
                <PlaceholderLoading shape="rect" width={288} height={50} />
              </div>
              <div className="gap-150">
                <PlaceholderLoading shape="rect" width={288} height={100} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="order-detail-card">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <PlaceholderLoading shape="rect" width={100} height={20} />
            </div>
            <div>
              <PlaceholderLoading shape="rect" width={140} height={30} />
            </div>
          </div>

          <div className="mt-3 d-flex">
            <div>
              <PlaceholderLoading shape="circle" width={60} height={60} />
            </div>
            <div className="ms-2">
              <PlaceholderLoading shape="rect" width={130} height={22} />
              <div className="mt-2">
                <PlaceholderLoading shape="rect" width={130} height={15} />
              </div>
            </div>
          </div>
        </div>

        <div className="order-detail-card f-16 c-black fw-600 mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <PlaceholderLoading shape="rect" width={100} height={20} />
            </div>
          </div>

          <div className="mt-2">
            <PlaceholderLoading shape="rect" width={160} height={23} />
          </div>
          <div className="mt-2">
            <PlaceholderLoading shape="rect" width={160} height={23} />
          </div>

          <div className="mt-3">
            <PlaceholderLoading shape="rect" width="100%" height={150} />

            <div className="mt-2 ">
              <PlaceholderLoading shape="rect" width="100%" height={20} />
            </div>
            <div className="mt-2">
              <PlaceholderLoading shape="rect" width="100%" height={40} />
            </div>

            <div className="mt-2">
              <PlaceholderLoading shape="rect" width="100%" height={20} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserDashboardOrdersLoader = () => {
  return (
    <Fragment>
      <div className="row">
        {Array.from({ length: 3 }).map((item, index) => {
          return (
            <div key={index} className="col-md-4">
              <PlaceholderLoading shape="rect" width="100%" height={250} />
              <div>
                <div className="mt-2">
                  <PlaceholderLoading shape="rect" width={100} height={23} />

                  <div className="mt-2">
                    <PlaceholderLoading shape="rect" width={200} height={23} />
                  </div>
                </div>

                <div className="flex mt-2">
                  <div>
                    <PlaceholderLoading shape="rect" width={100} height={23} />
                  </div>
                  <div>
                    <PlaceholderLoading shape="rect" width={80} height={23} />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export const MyFavorite = () => {
  return (
    <Fragment>
      {Array.from({ length: 3 }).map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="d-flex align-items-center">
                <PlaceholderLoading shape="rect" width={80} height={80} />
                <div className="ms-2">
                  <PlaceholderLoading shape="rect" width={160} height={18} />
                  <div className="mt-2">
                    <PlaceholderLoading shape="rect" width={160} height={35} />
                  </div>
                </div>
              </div>
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={18} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={18} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={18} />
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
};

export const SellerReview = () => {
  return (
    <Fragment>
      {Array.from({ length: 2 }).map((item, index) => {
        return (
          <div key={index} className="d-flex mb-3">
            <div className="mt-1">
              <PlaceholderLoading shape="circle" width={80} height={80} />
            </div>
            <div className="ms-3 w-100">
              <div>
                <PlaceholderLoading shape="rect" width={100} height={20} />
              </div>

              <div>
                <span>
                  <PlaceholderLoading shape="rect" width={300} height={20} />
                </span>
              </div>
              <div className="mt-2">
                <PlaceholderLoading shape="rect" width="100%" height={100} />
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export const PromotionLoader = () => {
  return (
    <Fragment>
      {Array.from({ length: 4 }).map((item, index) => {
        return (
          <tr key={index}>
            <td>
              <div className="d-flex align-items-center">
                <div className="">
                  <PlaceholderLoading shape="rect" width={30} height={30} />
                </div>

                <div className="ms-3">
                  <PlaceholderLoading shape="rect" width={60} height={60} />
                </div>

                <div className="ms-3">
                  <div className="">
                    <PlaceholderLoading shape="rect" width={250} height={40} />
                  </div>
                </div>
              </div>
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={130} height={40} />
            </td>

            <td>
              <PlaceholderLoading shape="rect" width={80} height={40} />
            </td>
          </tr>
        );
      })}
    </Fragment>
  );
};

export const PhotosLoader = () => {
  return (
    <div className="d-flex">
      {Array.from({ length: 6 }).map((item, index) => {
        return (
          <div className="m-2">
            <PlaceholderLoading shape="rect" width={100} height={100} />
          </div>
        );
      })}
    </div>
  );
};
