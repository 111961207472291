import React from "react";
import "./feature.css";
import { NavLink } from "react-router-dom";

const FeatureComponent = () => {
  return (
    <div>
      <div className="rentoho_feature_img mt-1">
        <div className="featureimg_overlly">
          <h4>Ensuring Safe Exchanges</h4>

          <div className="f-15 mt-3">
            At Rentoho, your safety and satisfaction are our top priorities. To
            guarantee secure transactions, we have implemented a robust system
            where both the buyer and seller must confirm the successful exchange
            of items.
          </div>

          <div className="mt-3">
            <NavLink to="/secure-transactions">
              <button className="my-button">Learn More</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeatureComponent;
