import React from "react";
import WebCameraComponents from "./WebCameraComponents";
import { useNavigate, useParams } from "react-router-dom";

const UploadProduct = () => {
  const params = useParams();

  const navigate = useNavigate();
  return (
    <div className="webcam-bg-black">
      <div className="container">
        <div className="row">
          <div className="offset-md-3 col-md-6">
            <div className="center-coman">
              <WebCameraComponents />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadProduct;
