import React from "react";

const RentohoFeauterText = () => {
  return (
    <div>
      <div className="feature mt-3">
        <div>
          <img src="/images/manage-ads.png" alt="image" />
        </div>

        <div className="ms-4">
          <div className="f-20 f-600">Manage Your Ads</div>
          <div className="f-16 c-gray">
            Easily manage and update your ads with our user-friendly interface.
            Keep your listings fresh and engaging to attract more buyers.
          </div>
        </div>
      </div>

      <div className="feature">
        <div>
          <img src="/images/chat-login.png" alt="image" />
        </div>

        <div className="ms-4">
          <div className="f-20 f-600">Chat with Anyone, Anytime</div>
          <div className="f-16 c-gray">
            Communicate with buyers, Renters, and sellers smoothly and easily.
            Our real-time chat feature ensures seamless and efficient
            transactions.
          </div>
        </div>
      </div>

      <div className="feature border-none">
        <div>
          <img src="/images/users.png" alt="image" />
        </div>

        <div className="ms-4">
          <div className="f-20 f-600">245,365 Verified User</div>
          <div className="f-16 c-gray">
            Join a community of 245,365 verified users. Experience secure and
            reliable transactions with our trusted network.
          </div>
        </div>
      </div>
    </div>
  );
};

export default RentohoFeauterText;
