import React, { useEffect } from "react";
import Banner from "../../components/header/Banner";
import Default from "../../components/default/Default";
const FeaturesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Default>
      <section>
        <Banner
          title="Secure Transactions"

        />
      </section>

      <section>
        <div className="container gap-40 mobile-gap mb-5">
          <div className="row">
            <div className="offset-md-2 col-md-8">
              <div className="f-32 fw-600 c-orange">Secure Transactions</div>

              <ol type="1" className="mt-4 fw-700 f-20">
                <li>
                  Transaction Initiation :{" "}
                  <span className="fw-400 c-gray f-16">
                    When a buyer and seller agree on a transaction, the process
                    begins securely within our platform.
                  </span>
                </li>

                <li className="mt-3">
                  Item Exchange :{" "}
                  <span className="fw-400 c-gray f-16">
                    The buyer receives the item from the seller.
                  </span>
                </li>

                <li className="mt-3">
                  Mutual Confirmation :{" "}
                  <span className="fw-400 c-gray f-16">
                    The buyer receives the item from the seller.
                  </span>
                </li>

                <li className="mt-3">
                  Successful Transaction :{" "}
                  <span className="fw-400 c-gray f-16">
                    Once both parties provide their confirmation, the
                    transaction is marked as successful and complete.
                  </span>
                </li>

                <div className="c-gray mt-3 fw-400 f-16">
                  This dual confirmation system ensures that both parties are
                  satisfied with the exchange, adding an extra layer of security
                  and trust to every transaction made on Rentoho. If either
                  party does not confirm, our support team is ready to assist in
                  resolving any issues promptly.
                </div>

                <div className="c-gray mt-3 fw-400 f-16">
                  Feel confident knowing that every transaction on Rentoho is
                  protected with our secure and transparent process. Your safety
                  is our commitment.
                </div>
              </ol>

              <div className="f-25 mt-4 c-orange fw-600">Order Tracking :</div>

              <ul>
                <li className="c-gray fw-400 f-16">
                  Track the status of your orders and rental agreements in
                  real-time.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default FeaturesPage;
