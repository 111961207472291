import React, { useEffect, useState } from "react";
import Banner from "../../components/header/Banner";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import RentohoFeauterText from "../../components/text/RentohoFeauterText";
import PostRequest from "../../services/PostRequest";
import { showToaster } from "../../config/common";
import Default from "../../components/default/Default";
import PhoneInput from "react-phone-input-2";
import { Formik } from "formik";
import * as Yup from "yup";


const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

// Validation Schema
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
});

// Initial Values
const adsInformationInitialValue = {
  phoneNumber: "",
};

const VerificationPhoneNumber = () => {
  const location = useLocation();
  // console.log("location", location);

  const [otp, setOtp] = useState("");
  // console.log("otp",otp);

  const navigate = useNavigate();

  const handelAddPhoneNumber = async (values) => {

    // console.log("values",values);
    // return false
    
    try {

      const data = {
        phoneNumber: values.phoneNumber,
        token: location.state,
      };

      const options = {
        showLoader: "otp-button",
      };
      const response = await PostRequest(
        "/user/add-phone-number",
        data,
        options
      );

      navigate("/verification", { state : response });


    } catch (error) {
      console.log("error", error);
      showToaster(error.message, "error");
    }
  };


  useEffect(() => {

    if(!location.state){
      navigate('/login')
    }

  },[location.state])

  if (!location.state) {
    return null;
  }

  return (
    <Default>
      <section>
        <Banner title="Phone Number Verification" />
      </section>

      <section>
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 hidden-in-mobile">
                <RentohoFeauterText />
              </div>
              <div className="col-md-6">
                <Formik
                  initialValues={adsInformationInitialValue}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    handelAddPhoneNumber(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    dirty,
                    setValues,
                    setErrors,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="login-bg">
                        <div className="t-center fw-600 mt-3">
                          <h3>Phone Number Verification</h3>
                          <div className="fw-600 mt-4 f-16 c-gray">
                          Enter your phone number bellow, <br /> we will  send OTP to verify phone number.






                          </div>
                        </div>

                        <PhoneInput
                          country={"us"}
                          containerClass="mt-5 cursor"
                          inputProps={{
                            name: "phoneNumber",
                            required: true,
                          }}
                          onChange={(phone, country) =>
                            setValues({
                              ...values,
                              phoneNumber: phone,
                            })
                          }
                          onBlur={handleBlur}
                          value={values.phoneNumber}
                        />

                        <div style={{ width: "100%", textAlign: "center" }}>
                          <button
                            id="otp-button"
                            className="my-button padd-btn-login w-100 mt-5 cursor"
                          >
                            Verify Phone Number
                          </button>
                        </div>

                        <div className="gap-30 t-center f-16 fw-600 mobile-mt-2">
                          Already have an account?{" "}
                          <NavLink to="/login">
                            <span className="c-orange cursor"> Sign In</span>
                          </NavLink>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>

              </div>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default VerificationPhoneNumber;
