import React, { useEffect } from "react";
import Banner from "../../components/header/Banner";
import "./about.css";
import HowitWork from "../../components/user/HowitWork";
import Default from "../../components/default/Default";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Default>
      <section>
        <Banner title="About Us" />
      </section>

      <section>
        <div className="container mt-4">
          <div className="row">
            <div className="col-md-6">
              <div className="pt-3">
                <div className="f-32 fw-600 c-orange">About Us</div>

                <div className="f-16 fw-400 c-gray mt-4 mobile-mt-2">
                  Welcome to Rentoho, your premier online classifieds platform
                  that goes beyond traditional buying and selling. Founded by an
                  international team headquartered in Ontario, Canada, Rentoho
                  was born out of a mission to make classifieds transactions
                  more secure and expand the functionalities available to users.
                  Whether you're looking to buy, sell, or rent items, Rentoho
                  provides a safe and efficient platform to meet your needs.
                  <div className="mt-2">
                    At Rentoho, we understand the challenges of traditional
                    classifieds. That's why we have integrated advanced security
                    measures to ensure your transactions are safe and reliable.
                    Our platform offers a wide range of features designed to
                    enhance your experience, making it easier than ever to
                    connect with others and find exactly what you're looking
                    for.
                  </div>
                  <div className="mt-2">
                    We are also driven by the mission to empower everyone to
                    make a passive income by renting out their unused
                    possessions. With Rentoho, you can turn items sitting idle
                    into a source of revenue. Additionally, for those who need
                    something temporarily, our platform offers a cost-effective
                    alternative to making big purchases, allowing you to save
                    money.
                  </div>
                  <div className="mt-2">
                    Join our growing community and experience the future of
                    classifieds. With Rentoho, you're not just buying, selling,
                    or renting items—you're part of a trusted network that
                    values security, convenience, and innovation, while
                    empowering you to make the most of your resources.
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-center mt-5 pt-5 mobile-padd-remove">
                <img
                  height={400}
                  className="about-img"
                  src="/images/aboutimg.png"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <HowitWork />
      </section>
    </Default>
  );
};

export default AboutUs;
