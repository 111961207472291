import moment from "moment";
import React from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { NavLink } from "react-router-dom";

const ConversionsItems = (props) => {
  const user = props.lastMessage;

  // console.log("user", user);

  const now = moment();
  const messageTime = moment(user.lastMessage.createdAt);
  const hours = now.diff(messageTime, "hours");
  const minutes = now.diff(messageTime, "minutes") % 60;

  return (
    <NavLink to={`/user/messages/${user.user}`} state={user.userDetails}>
      <div className="bg-hover-message gap-24 cursor">
        <div className="d-flex align-items-center ">
          <UserAvatar
            className="f-20"
            url={user.userDetails.profileImage}
            name={user.userDetails.name}
            size="45"
          />

          <div className="ms-2">
            <div className="fw-600 f-16 c-black">{user.userDetails.name}</div>
            <div className="c-gray f-14 fw-400 text-message-overflow">
              {user.lastMessage.message}
            </div>
          </div>
        </div>

        <div>
          {user.unreadCount != 0 && (
            <div className="message-count ms-1">{user.unreadCount}</div>
          )}

          <div className="fw-400 f-14 c-gray">
            {moment(user.lastMessage.createdAt).fromNow(true)}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default ConversionsItems;
