import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import PostRequest from "../../services/PostRequest";
import { FaStar } from "react-icons/fa";

import UserAvatar from "../../components/user/UserAvatar";
import { FaCircleCheck } from "react-icons/fa6";
import { SlLocationPin } from "react-icons/sl";
import { LuPhoneCall } from "react-icons/lu";
import { TfiEmail } from "react-icons/tfi";
import { GoAlert } from "react-icons/go";
import ProductItem from "../../components/products/productItem/ProductItem";
import {
  AdsItemCardLoader,
  SellerReview,
} from "../../components/loader/AdsItemLoader";
import { PiNotepadLight } from "react-icons/pi";
import SellerReviewComponent from "../../components/seller/SellerReviewComponent";
import Rating from "react-rating";
import { CiStar } from "react-icons/ci";
import Modal from "react-bootstrap/Modal";
import LoginFormComponent from "../../components/LoginFormComponent";
import { getAuthUser, showToaster } from "../../config/common";
import LottieLoader from "../../components/lottieloader/LottieLoader";
import Default from "../../components/default/Default";
import Banner from "../../components/header/Banner";
import "./seller-profile.css";
const SellerProfile = () => {
  const location = useLocation();

  const sellerData = location.state;

  const params = useParams();

  const loginUser = getAuthUser();

  // const navigate = useNavigate()

  const [sellerDetails, setSellerDetails] = useState(sellerData || false);

  // console.log("sellerDetails", sellerDetails);

  const [activeSellerTab, setActiveSellerTab] = useState("Recent Ads");

  const [isLoading, setIsLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState({});
  const [adsListingData, setAdsListingData] = useState([]);
  const [hasLoadMore, setHasLoadMore] = useState(true);

  const [loader, setLoader] = useState(false);

  const [sellerReview, setSellerReview] = useState([]);

  const [show, setShow] = useState(false);

  const [reportUser, setReportUser] = useState(false);

  // const [ reportMessage , setReportMessage ] = useState("")

  // const [reportReason, setReportReason] = useState('')

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const reportClose = () => setReportUser(false);
  const reportShow = () => setReportUser(true);

  const [starRating, setStarRating] = useState(0);

  const [textValue, setTextValue] = useState("");

  const loadSellerDetails = async () => {
    try {
      const data = {
        user: params.id,
      };
      const response = await PostRequest("/user/profile", data);
      setSellerDetails(response);
      // console.log("response", response);
    } catch (error) {}
  };

  const handelSellerTab = (tab) => {
    setActiveSellerTab(tab);
  };

  const loadAdsListings = async (isLoadMore = false, hasExtraPayload = {}) => {
    try {
      //

      if (!isLoadMore) {
        setIsLoading(true);
      }

      activeFilter.limit = 12;
      const data = { ...activeFilter, ...hasExtraPayload };
      data.user = params.id;

      const options = {
        showLoader: "load-more",
      };

      const response = await PostRequest("/user/ads", data, options);

      // console.log('response',response);
      if (isLoadMore) {
        setAdsListingData((prev) => [...prev, ...response]);
      } else {
        setAdsListingData(response);
      }

      if (response.length < 12) {
        setHasLoadMore(false);
      }
    } catch (error) {
    } finally {
      if (!isLoadMore) {
        setIsLoading(false);
      }
    }
  };

  // const loadMoreProducts = () => {
  //   const data = {
  //     skip: adsListingData.length,
  //   };
  //   loadAdsListings(true, data);
  // };

  const handleTextMessage = (event) => {
    setTextValue(event.target.value);
  };

  const handelChangeStar = (star) => {
    setStarRating(star);
  };

  const handelCallBack = () => {
    handleClose();
  };

  const handelActiveAds = (tab) => {
    setActiveSellerTab(tab);
  };

  const handelSubmitReview = async () => {
    try {
      if (textValue == "" || starRating == 0) {
        return false;
      }
      setLoader(true);
      const data = {
        user: sellerDetails._id,
        review: textValue,
        rating: starRating,
      };

      const options = {
        showLoader: "review-button",
      };
      await PostRequest("/rating/post", data, options);
      loadSellerReview();

      setActiveSellerTab("Seller Review");
      loadSellerDetails();
    } catch (error) {
      showToaster("Users cannot rate themselves", "error");
      console.log(error.message);
    } finally {
      setLoader(false);
    }
  };

  const loadSellerReview = async () => {
    try {
      const data = {
        user: sellerDetails._id,
      };

      const response = await PostRequest("/ratings", data);
      setSellerReview(response);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handelSubmitReport = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      const options = {
        showLoader: "report-btn",
      };

      data.user = loginUser;

      await PostRequest("/seller/report", data, options);

      showToaster(
        "Thank you! We’ve received your report and will take appropriate action."
      );
      setReportUser(false);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadSellerDetails();
    loadAdsListings();
    loadSellerReview();
  }, [params.id]);

  if (!sellerDetails) {
    return <LottieLoader />;
  }

  return (
    <Default>
      <section>
        <Banner title={sellerDetails.name} />
      </section>

      <section>
        <div className="container gap-30 mb-4">
          <div className="row">
            <div className="col-md-3">
              <div className="seller-profile-said-bar">
                <div className="seller-profile-center">
                  <UserAvatar
                    size="120"
                    url={sellerDetails.profileImage}
                    name={sellerDetails.name}
                  />
                  <div className="f-20 fw-600 mt-3">
                    {sellerDetails.name}
                    {sellerDetails.isVerifiedUser && (
                      <span className="c-green ms-1">
                        <FaCircleCheck size={15} />
                      </span>
                    )}
                  </div>

                  <div className="f-16 mt-1">
                    <span>
                      <FaStar className="c-orange" />{" "}
                    </span>
                    <span className="mt-2">
                      {" "}
                      {sellerDetails.averageRating} Star Rating
                    </span>
                  </div>
                </div>
                <div className="b-line-bottom my-4"></div>
                <span className="f-16 fw-600 c-gray">CONTACT INFORMATION</span>

                <div className="mt-3">
                  <div>
                    <span>
                      <LuPhoneCall size={20} className="c-orange" />
                    </span>
                    <span className="ms-2 f-16 fw-400">
                      {sellerDetails.phoneNumber}
                    </span>
                  </div>

                  <div className="mt-2 cursor text-one-line">
                    <span>
                      <TfiEmail size={19} className="c-orange" />
                    </span>
                    <span className="ms-2 f-16 fw-400">
                      {sellerDetails.email}
                    </span>
                  </div>
                  {sellerDetails?.address?.address != "" && (
                    <div className="mt-2 cursor text-one-line">
                      <span className="">
                        <SlLocationPin size={20} className="c-orange" />
                      </span>
                      <span className="ms-2 f-16 fw-400">
                        {sellerDetails?.address?.address}
                      </span>
                    </div>
                  )}
                </div>

                <div className="b-line-bottom my-4"></div>

                <div onClick={reportShow} className="cursor c-gray">
                  <span>
                    <GoAlert size={23} />
                  </span>{" "}
                  <span className="f-16 fw-600 ms-2 mt-1">Report Seller</span>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="seller-profile-ads p-13">
                <div className="row">
                  <div className="col-md-9">
                    <div className="d-flex align-items-center">
                      <div className="ranting-box-bg">
                        {!sellerDetails.averageRating ? (
                          "0"
                        ) : (
                          <Fragment>{sellerDetails.averageRating}</Fragment>
                        )}
                      </div>

                      <div className="ms-3">
                        <Rating
                          readonly
                          initialRating={sellerDetails.averageRating}
                          emptySymbol={<CiStar size={23} />}
                          fullSymbol={<FaStar className="c-orange" size={20} />}
                        />

                        <div className="fw-600 f-16 c-black">
                          {sellerDetails.averageRating} Star Average Rating
                        </div>
                        <div className="fw-400 f-14 c-gray">
                          {sellerDetails.totalRating} Reviews
                        </div>
                      </div>

                      <button
                        onClick={() => handelSellerTab("Write Review")}
                        className="my-button ms-3  hidden-in-mobile "
                      >
                        {" "}
                        <FaStar /> Write Review
                      </button>
                    </div>

                    <button
                      onClick={() => handelSellerTab("Write Review")}
                      className="my-button mt-3 hidden-in-desktop mobile-block"
                    >
                      {" "}
                      <FaStar /> Write Review
                    </button>
                  </div>
                  <div className="col-md-3 mobile-mt-3">
                    <div
                      onClick={() => handelActiveAds("Recent Ads")}
                      className="seller-active-ads d-flex justify-content-between align-items-center cursor"
                    >
                      <div>
                        <div className="f-20 fw-600">
                          {adsListingData.length}
                        </div>
                        <div className=" fw-400 f-16">Active Ads</div>
                      </div>

                      <div className="icon-seller-active-ads">
                        <PiNotepadLight color="#00AAFF" size={25} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="seller-profile-ads mt-4">
                <div className="p-15 mobile-p-14">
                  <span
                    onClick={() => handelSellerTab("Recent Ads")}
                    className={`tab-span mobile-font-13 ${
                      activeSellerTab == "Recent Ads" ? "seller-active-tab" : ""
                    }`}
                  >
                    Recent Ads
                  </span>
                  <span
                    onClick={() => handelSellerTab("Seller Review")}
                    className={`tab-span mobile-font-13 ${
                      activeSellerTab == "Seller Review"
                        ? "seller-active-tab"
                        : ""
                    }`}
                  >
                    Seller Reviews
                  </span>
                  <span
                    onClick={() => handelSellerTab("Write Review")}
                    className={` tab-span mobile-font-13 ${
                      activeSellerTab == "Write Review"
                        ? "seller-active-tab"
                        : ""
                    }`}
                  >
                    Write Review
                  </span>
                </div>
                <div className="b-line-bottom"></div>

                <div className="p-15 mt-2">
                  {activeSellerTab == "Recent Ads" && (
                    <Fragment>
                      <div>
                        {isLoading ? (
                          <AdsItemCardLoader />
                        ) : (
                          <div className="row">
                            {adsListingData.map((product, index) => {
                              return (
                                <div key={index} className="col-md-4 mb-4">
                                  <ProductItem key={index} product={product} />
                                </div>
                              );
                            })}

                            {adsListingData.length == 0 && (
                              <h5 className="c-gray t-center mt-3">
                                "0" active posts.
                              </h5>
                            )}
                          </div>
                        )}
                      </div>
                    </Fragment>
                  )}

                  {activeSellerTab == "Seller Review" && (
                    <Fragment>
                      <div className="row">
                        {loader ? (
                          <SellerReview />
                        ) : (
                          <Fragment>
                            {sellerReview.map((userReview, index) => {
                              return (
                                <div key={index} className="col-md-12">
                                  <SellerReviewComponent
                                    callback={loadSellerReview}
                                    callBackDetails={loadSellerDetails}
                                    data={userReview}
                                  />
                                </div>
                              );
                            })}
                          </Fragment>
                        )}
                      </div>

                      {sellerReview.length == 0 && (
                        <div className="text-center">
                          <h5>"0" reviews.</h5>
                        </div>
                      )}
                    </Fragment>
                  )}

                  {activeSellerTab == "Write Review" && (
                    <div className="">
                      <Rating
                        initialRating={starRating}
                        onChange={handelChangeStar}
                        emptySymbol={<CiStar size={30} />}
                        fullSymbol={<FaStar className="c-orange" size={25} />}
                      />
                      <textarea
                        maxLength={500}
                        onChange={handleTextMessage}
                        className="text-area-input cursor w-100 gap-15"
                        rows="4"
                        placeholder="Share our thoughts about the seller/buyer..."
                        required
                      ></textarea>

                      <button
                        id="review-button"
                        onClick={loginUser ? handelSubmitReview : handleShow}
                        className="coman-btn gap-25"
                        disabled={textValue == "" || starRating == 0}
                      >
                        Submit Review
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Model popup Login user */}

      <div className="seller-model">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            {/* <Modal.Title></Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <LoginFormComponent callback={handelCallBack} />
          </Modal.Body>
        </Modal>
      </div>

      {/* model report seller */}

      <Modal show={reportUser} onHide={reportClose}>
        <Modal.Header closeButton>
          <Modal.Title>Report To Seller</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="fw-600 f-17 mt-4">
            Select Reason for Reporting Seller
          </div>

          <form action="" onSubmit={handelSubmitReport}>
            <select className="my-input mt-2" required name="reason">
              <option value="Item Not as Described">
                Item Not as Described
              </option>
              <option value="Counterfeit or Fake Products">
                Counterfeit or Fake Products
              </option>
              <option value="Scam or Fraud">Scam or Fraud</option>
              <option value="Harassment or Abusive Behavior">
                Harassment or Abusive Behavior
              </option>
              <option value="Unauthorized Charges">Unauthorized Charges</option>
              <option value="Shipping Issues">Shipping Issues</option>
              <option value="Violation of Platform Policies">
                Violation of Platform Policies
              </option>
              <option value="Inaccurate Contact Information">
                Inaccurate Contact Information
              </option>
              <option value="Poor Customer Service">
                Poor Customer Service
              </option>
              <option value="other">Other</option>
            </select>

            <textarea
              className="text-area-input cursor w-100 mt-3"
              required
              // onChange={(e) => setReportMessage(e.target.value)}
              maxLength={200}
              placeholder="write your message..."
              rows="3"
              name="message"
            ></textarea>

            <div className="t-end mt-3">
              <button id="report-btn" className="coman-btn">
                Report This Seller
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </Default>
  );
};

export default SellerProfile;
