import React, { Fragment, useEffect, useState } from "react";

import { Table } from "react-bootstrap";
import { RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";
import { TbDots } from "react-icons/tb";
import { IoEyeOutline, IoSearchOutline } from "react-icons/io5";
import PostRequest from "../../../services/PostRequest";
import moment from "moment";
import { formatter, showToaster } from "../../../config/common";
import { NavLink, useNavigate } from "react-router-dom";
import { AdsItemLoader } from "../../loader/AdsItemLoader";
import "./myproductitems.css";
const ProductItem = ({ ad, callback }) => {
  const navigate = useNavigate();

  const [dropdown, setDropdown] = useState(false);

  const handelAdsDelete = async (id) => {
    try {
      const data = {
        ad: id,
      };

      const options = {
        showLoader: ad._id,
      };

      if (!window.confirm("Are you sure you want to delete this ad?"))
        return false;

      const response = await PostRequest(`/ad/delete`, data, options);

      callback();

      showToaster("Ads Delete Successfull");
    } catch (error) {
      console.log("error", error.message);
      showToaster("Not Delete", "error");
    }
  };

  const handleShow = () => {
    setDropdown(!dropdown);
  };

  const handelUpdateAds = () => {
    navigate(`/user/post/edit/${ad._id}`, { state: ad });
  };

  return (
    <tr className="rel">
      <td>
        <div className="d-flex align-items-center">
          <div className="rel">
            <NavLink to={`/ads/${ad.slug}/${ad._id}`} state={ad}>
              <img
                className="product-image cursor"
                src={ad.mainImage}
                alt="image"
                width="50"
              />{" "}
            </NavLink>
            <div className="bg-type">{ad.transactionType}</div>
          </div>

          <div className="ms-3 mobile-w-90 mobile-table-one-line">
            <span>{ad.title}</span>
          </div>
        </div>
      </td>
      <td className="c-gray f-13 mobile-table-date">
        {moment(ad.createdAt).format("lll")}
      </td>
      <td className="c-gray-dark fw-600">{formatter.format(ad.price)}</td>
      <td
        className={ad.status === "Available" ? "text-success" : "text-danger"}
      >
        {ad.status}
      </td>
      <td className="mobile-table-action">
        <button
          onClick={handelUpdateAds}
          className="btn-orange-outline mobile-edit-btn"
        >
          <RiEdit2Line className="f-20 mobile-font-12" /> &nbsp; Edit
        </button>

        <button
          onClick={handleShow}
          className="ms-2 btn-orange-outline mobile-dot-btn"
        >
          <TbDots size={20} />
        </button>
      </td>

      {dropdown && (
        <div className="drowpdown-myads">
          <NavLink
            to={`/ads/${ad.slug}/${ad._id}`}
            className="c-black"
            state={ad}
          >
            <div className="dropdown-bgcolor cursor">
              <IoEyeOutline size={18} />{" "}
              <span className="ms-2">View Ad Details</span>
            </div>
          </NavLink>

          <div
            id={ad._id}
            onClick={() => handelAdsDelete(ad._id)}
            className="dropdown-bgcolor cursor"
          >
            <RiDeleteBinLine size={18} />{" "}
            <span className="ms-2">Delete Ad</span>
          </div>
        </div>
      )}
    </tr>
  );
};

var searchTime;
const MyProductItems = (props) => {
  const [ads, setAds] = useState();

  const [loading, setLoading] = useState(false);

  const [activeFilter, setActiveFilter] = useState({});

  const [userPostItem, setUserPostItem] = useState([]);

  // console.log('userPostItem',userPostItem);/

  const loadUserAds = async () => {
    try {
      setLoading(true);
      const response = await PostRequest("/user/ads", activeFilter);
      setUserPostItem(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handelOnChangeTransactionType = (event) => {
    setActiveFilter((pre) => ({
      ...pre,
      transactionType: event.target.value == "All" ? null : event.target.value,
    }));
  };

  const handelOnChangeStatus = (event) => {
    setActiveFilter((pre) => ({
      ...pre,
      status: event.target.value == "All" ? null : event.target.value,
    }));
  };

  const handelOnChangeSearch = (event) => {
    if (searchTime) {
      clearTimeout(searchTime);
    }
    setTimeout(() => {
      setActiveFilter((pre) => ({ ...pre, search: event.target.value }));
    }, 500);
  };

  useEffect(() => {
    loadUserAds();
  }, [activeFilter]);

  return (
    <div>
      <div className="row">
        <div className="col-md-5">
          <div className="input-head">
            <IoSearchOutline size={20} color="#F59449" />
            <input
              onChange={handelOnChangeSearch}
              type="text"
              className="input-search ms-2"
              placeholder="Ads tittle, keyword..."
            />
          </div>
        </div>
        <div className="col-md-7">
          <div className="row">
            <div className="offset-md-4 col-md-4 mobile-mt-2">
              <select
                onChange={handelOnChangeTransactionType}
                className="my-input c-black"
              >
                <option value="All">All</option>
                <option value="Sell">Sell</option>
                <option value="Rent">Rent</option>
              </select>
            </div>

            <div className="col-md-4 mobile-mt-2">
              <select
                onChange={handelOnChangeStatus}
                className="my-input c-black"
              >
                <option value="">All</option>
                <option value="Available">Available</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div className="gap-30"></div>

      <Table className="user-ads-list-table" hover responsive>
        <thead>
          <tr>
            <th className="th-title-50">Ads</th>
            <th>Date</th>
            <th>Prices</th>
            <th>Status</th>
            <th width="200px">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <AdsItemLoader length={3} />
          ) : (
            <Fragment>
              {userPostItem.map((ad, index) => (
                <ProductItem callback={loadUserAds} key={index} ad={ad} />
              ))}
            </Fragment>
          )}

          {loading ? (
            ""
          ) : (
            <Fragment>
              {userPostItem.length == 0 && (
                <tr className="t-center">
                  <td colSpan={5}>
                    <h5 className="c-gray mt-3">"0" ads.</h5>
                  </td>
                </tr>
              )}
            </Fragment>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default MyProductItems;
