import React from "react";
import { isMobile } from "../../config/common";
import Footer from "../footer/Footer";
import MobileHeader from "../header/mobileheader/MobileHeader";
import MyHeader from "../header/MyHeader";

const Default = (props) => {
  return (
    <div className="rth-main">
      {isMobile() ? <MobileHeader /> : <MyHeader />}

      {props.children}

      <div className="body-main"></div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Default;
