import React from "react";

import { MdOutlineEmail } from "react-icons/md";
import { NavLink } from "react-router-dom";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { categories } from "../../data/categories";
import { toSlug } from "../../config/common";
import "./footer.css";
const Footer = () => {
  return (
    <footer className="mt-4">
      <div className="footer-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4 className="c-white">Subscribe to our newsletter</h4>

              <div className="mt-3 f-15 mobile-footer">
                ubscribe to our newsletter for the latest updates, exclusive
                offers, and insightful content delivered straight to your inbox.
                Don’t miss out—join our community today!
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-4">
              <div className="footer-input">
                <div>
                  <MdOutlineEmail size={19} className="c-orange" />

                  <input
                    type="text"
                    className="input-search-footer ms-2"
                    placeholder="Email address"
                  />
                </div>

                <div>
                  <button className="my-button ms-3">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-4" />

        <div className="container">
          <div className="row mt-5">
            <div className="col-md-3">
              <img
                className="footer-logo mt-1"
                src="/images/logo.png"
                alt="image_footer"
              />

              <div className="f-15 mt-4">
                <div>2002-505 Talbot St., London, ON N6A 2S6</div>
                <div className="mt-2">Phone : (226) 791-1310</div>

                <div className="mt-2">Mail : support@rentoho.com</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-4 mobile-mt-3">
                  <div className="f-18 c-white">Supports</div>

                  <div className="mt-4 f-15 mobile-mt-2">
                    <div>
                      <NavLink to="/contact" className="c-white">
                        Contact
                      </NavLink>
                    </div>

                    <div className="mt-2">
                      <NavLink to="/faq" className="c-white">
                        FAQs
                      </NavLink>
                    </div>

                    <div className="mt-2">
                      <NavLink to="/secure-transactions" className="c-white">
                        Secure Transactions
                      </NavLink>
                    </div>

                    <div className="mt-2">
                      <NavLink to="/how-it-works" className="c-white">
                        How It Works
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mobile-mt-3">
                  <div className="f-18 c-white">Quick Links</div>

                  <div className="mt-4 f-15 mobile-mt-2">
                    <div>
                      <NavLink to="/about" className="c-white">
                        About
                      </NavLink>
                    </div>

                    <div className="mt-2">
                      <NavLink to="/teamandcondition" className="c-white">
                        Teams & Conditions
                      </NavLink>
                    </div>

                    <div className="mt-2">Privacy & Policies</div>

                    <div className="mt-2">
                      <NavLink to="/user/post/create" className="c-white">
                        Post a Ads
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mobile-mt-3">
                  <div className="f-18 c-white">Category</div>

                  <div className="mt-4 f-15 mobile-mt-2">
                    {categories.slice(0, 4).map((category, index) => (
                      <div key={index} className="mt-2">
                        <NavLink
                          to={`/ads/listings/${toSlug(category.category)}`}
                          className="c-white"
                        >
                          {category.category}
                        </NavLink>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 mobile-mt-3">
              <div className="f-18 c-white">Download our app</div>

              <div className="d-flex mt-4 mobile-mt-2">
                <div className="footer-icon">
                  <a
                    target="__blank"
                    href="https://www.facebook.com/people/Rentoho/61564575851644/"
                  >
                    <FaFacebookF />
                  </a>
                </div>

                <div className="footer-icon ms-1">
                  <a target="__blank" href="https://x.com/Rentoho">
                    <FaTwitter />
                  </a>
                </div>

                <div className="footer-icon ms-1">
                  <a target="__blank" href="https://www.instagram.com/rentoho/">
                    <FaInstagram />
                  </a>
                </div>

                <div className="footer-icon ms-1">
                  <a
                    target="__blank"
                    href="https://www.linkedin.com/company/rentoho-app/"
                  >
                    <FaLinkedinIn />
                  </a>
                </div>

                {/* <div className="footer-icon ms-1">
                  <a target="__blank" href="">

                  <FaWhatsapp />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
