import React, { useEffect } from "react";
import { GoStack } from "react-icons/go";
import { CiLocationOn } from "react-icons/ci";

import { NavLink } from "react-router-dom";
import { formatter } from "../../../config/common";
import "./productitem.css";

const ProductItem = (props) => {
  const productItem = props.product;

  // console.log('productItem',productItem);

  return (
    <div>
      <NavLink
        to={`/ads/${productItem.slug}/${productItem._id}`}
        state={productItem}
      >
        <div className="cursor box-ads rel">
          <img
            className="ads-img"
            src={productItem.mainImage}
            alt="product_image"
          />

          {/* <div className="promote-btn">FEATURED</div> */}

          <div className="ads-bg-white">
            <div className="f-14 c-gray">
              <GoStack />{" "}
              <span className="ms-1 mt-1">{productItem?.category}</span>
            </div>

            <div className="f-16 c-title fw-600 mt-2 one-line-text">
              {productItem?.title}
            </div>

            <div className="f-15 mt-3">
              <div className="row">
                <div className="col-md-5">
                  {productItem?.contactDetails?.city && (
                    <div className="d-flex align-items-center text-one-line">
                      <div>
                        <CiLocationOn size={16} color="#27C200" />
                      </div>
                      <div className="ms-1 mt-1 c-gray">
                        {productItem?.contactDetails?.city}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-7">
                  <div className="t-end f-16 fw-600 c-orange">
                    {formatter.format(productItem.price)}{" "}
                    {productItem.transactionType == "Rent" &&
                      productItem.priceType != "One Time" && (
                        <span className="c-gray f-12">
                          / {productItem.priceType}
                        </span>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
};

export default ProductItem;
