import React, { Fragment, useEffect, useState } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import { IoSearchOutline } from "react-icons/io5";
import { Table } from "react-bootstrap";
import PostRequest from "../../../services/PostRequest";
import OrdersItems from "./OrdersItems";
import { MyOrdersLoader } from "../../../components/loader/AdsItemLoader";
import { useSearchParams } from "react-router-dom";

var searchTimeout;

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const [loader, setLoader] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const runningOrders = searchParams.get("orders");

  // console.log("runningOrders",runningOrders);

  const [activeFilters, setActiveFilters] = useState({});

  const loadOrders = async () => {
    try {
      setLoader(true);

      const response = await PostRequest("/user/orders", activeFilters);

      setOrders(response);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };

  const handelOnChangeOrderType = async (type) => {
    setActiveFilters((prev) => ({ ...prev, orderType: type }));
  };

  const handleSearchOrder = (event) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setActiveFilters((prev) => ({ ...prev, search: event.target.value }));
    }, 500);
  };

  const handelOnChangeStatus = async (status) => {
    try {
      setActiveFilters((prev) => ({
        ...prev,
        status: status == "All" ? null : status,
      }));
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    loadOrders();
  }, [activeFilters]);

  // useEffect(() => {
  //   loadOrders()
  // }, [runningOrders])

  return (
    <DefaultDashboard title="My Orders">
      <div className="bg-user-post-ad p-3">
        <div className="row">
          <div className="col-md-5">
            <div className="input-head">
              <IoSearchOutline size={20} color="#F59449" />
              <input
                type="text"
                className="input-search ms-2"
                placeholder="Ads tittle, keyword..."
                onChange={handleSearchOrder}
              />
            </div>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="offset-md-4 col-md-4 mobile-mt-2">
                <select
                  name=""
                  onChange={(e) => handelOnChangeOrderType(e.target.value)}
                  className="my-input c-black"
                  id=""
                >
                  <option value="All">All</option>
                  <option value="Sell">Buy</option>
                  <option value="Rent">Rent</option>
                </select>
              </div>

              <div className="col-md-4 mobile-mt-2">
                <select
                  onChange={(e) => handelOnChangeStatus(e.target.value)}
                  className="my-input c-black"
                  id=""
                >
                  <option value="All">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Running">Running</option>
                  <option value="Accepted">Accepted</option>
                  <option value="Canceled">Canceled</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="gap-30">
          <div className="row">
            <Table className="user-ads-list-table" hover responsive>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>Prices</th>
                  <th>Status</th>
                  <th width="110px">Action</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <MyOrdersLoader />
                ) : (
                  <Fragment>
                    {orders.map((order, index) => {
                      return <OrdersItems key={index} orderItem={order} />;
                    })}
                  </Fragment>
                )}

                {loader ? (
                  ""
                ) : (
                  <Fragment>
                    {orders.length == 0 && (
                      <tr className="t-center">
                        <td colSpan={5}>
                          <h5 className="c-gray mt-3">
                            "0" orders.
                          </h5>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default UserOrders;
