import React, { useEffect, useState } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";

import { RiDeleteBin5Line } from "react-icons/ri";
import { getAuthUser, showToaster } from "../../../config/common";
import PostRequest from "../../../services/PostRequest";
import { City, Country, State } from "country-state-city";
import PhoneInput from "react-phone-input-2";

import UserAvatar from "../../../components/user/UserAvatar";
import { FaRegEyeSlash } from "react-icons/fa";
import { LuEye } from "react-icons/lu";
import LottieLoader from "../../../components/lottieloader/LottieLoader";
import "./user-profile-details.css";
const UserProfileDetails = () => {
  const authUser = getAuthUser();

  const [countryState, setCountryState] = useState([]);
  const [stateCity, setStateCity] = useState([]);
  const [profileData, setProfileData] = useState(authUser);
  const [activeCountry, setActiveCountry] = useState("");

  const [show, setShow] = useState(false);
  const [correctPass, setCorrectPass] = useState(false);
  const [isAddress, setIsAddress] = useState(false);

  const handleOnChangeCountry = (countyCode) => {
    setActiveCountry(countyCode);
    setCountryState(State.getStatesOfCountry(countyCode));
  };

  const handleOnChangeState = (stateCode) => {
    setStateCity(City.getCitiesOfState(activeCountry, stateCode));
  };

  // const handleChange = (e) => {

  // }

  const handelChangePassword = async (event) => {
    try {
      event.preventDefault();
      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      const option = {
        showLoader: "password-btn",
      };

      await PostRequest("/change-password", data, option);
      showToaster("Your password has been changed Successfully.");
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  // const passwordValueRemove = () => {
  //   setTimeout(() => {
  //     document.getElementById("valueremove").value = "";
  //   }, 600);
  // };

  const handelSubmitProfile = async (event) => {
    try {
      event.preventDefault();
      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      const options = {
        showLoader: "profileUpdate-btn",
      };
      const response = await PostRequest("/profile/update", data, options);
      showToaster("Your profile has been Successfully updated!");

      window.localStorage.setItem("auth", JSON.stringify(response));
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  const handelProfileUpload = async (event) => {
    const formData = new FormData();

    console.log("event", event.target.files[0]);

    formData.append("image", event.target.files[0]);

    const option = {
      showLoader: "upload-image-id",
    };

    const Url = await PostRequest("/upload/one", formData, option);
    const data = {
      profileImage: Url,
    };
    const response = await PostRequest("/profile/update", data, option);
    console.log("response", response);

    window.localStorage.setItem("auth", JSON.stringify(response));
    setProfileData(response);

    // loadProfile()
  };

  const loadProfile = async () => {
    try {
      const response = await PostRequest("/profile");

      setProfileData(response);

      // console.log("response", response);

      handleOnChangeCountry(response.address.country);
      setStateCity(
        City.getCitiesOfState(response.address.country, response.address.state)
      );

      setTimeout(() => {
        setIsAddress(true);
      }, 2000);
    } catch (error) {}
  };

  const handelShowpassword = () => {
    setShow(!show);
  };

  const handelShowpasswordCorrect = () => {
    setCorrectPass(!correctPass);
  };

  useEffect(() => {
    loadProfile();
  }, []);

  if (!profileData) {
    return <LottieLoader />;
  }

  return (
    <DefaultDashboard title="Account Settings">
      <div className="bg-user-post-ad p-4">
        <span className="f-20 fw-600">Account Information</span>
        <div className="gap-25 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center ">
            <div>
              <UserAvatar
                size="80"
                url={profileData?.profileImage}
                name={profileData?.name}
              />
            </div>

            <div>
              <input
                onChange={handelProfileUpload}
                type="file"
                id="profileimg"
                style={{ display: "none" }}
              />
              <label
                htmlFor="profileimg"
                id="upload-image-id"
                className="coman-lable  ms-3"
              >
                Choose image
              </label>
            </div>
          </div>

          <div className="hidden-in-mobile">
            <span className="fw-600 f-16">Email : </span>{" "}
            <span className="fw-400 c-gray">{profileData?.email}</span>
          </div>
        </div>

        <div className="mt-3 hidden-in-desktop mobile-block">
          <span className="fw-600 f-16">Email : </span>{" "}
          <span className="fw-400 c-gray">{profileData?.email}</span>
        </div>

        <div className="gap-25 mobile-mt-3">
          <form onSubmit={handelSubmitProfile}>
            <div className="row">
              <div className="col-md-6">
                <div className="fw-600 f-16">Full Name</div>
                <input
                  className="my-input mt-1"
                  type="text"
                  placeholder="Full Name"
                  name="name"
                  defaultValue={profileData?.name}
                />
              </div>
              <div className="col-md-6 mobile-mt-2">
                <div className="fw-600 f-16">Phone Number</div>
                <PhoneInput
                  country={"us"}
                  containerClass="mt-1 cursor"
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                  }}
                  value={profileData?.phoneNumber}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="fw-600 f-16 mt-3">Address</div>
                <input
                  className="my-input mt-1"
                  type="text"
                  placeholder="Enter address"
                  name="address"
                  defaultValue={profileData?.address?.address}
                />
              </div>
            </div>

            <div className="row mt-3 mobile-mt-2">
              <div className="col-md-6">
                <div className="fw-600 f-16">Country</div>
                <select
                  onChange={(e) => {
                    handleOnChangeCountry(e.target.value);
                  }}
                  name="country"
                  className="my-input mt-1"
                  defaultValue={profileData?.address?.country}
                >
                  <option value="">Select Country...</option>
                  {Country.getAllCountries().map((country, index) => {
                    return (
                      <option key={index} value={country.isoCode}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-6 mobile-mt-2">
                <div required className="fw-600 f-16">
                  State
                </div>
                <select
                  defaultValue={profileData?.address?.state}
                  onChange={(e) => {
                    handleOnChangeState(e.target.value);
                  }}
                  name="state"
                  className="my-input mt-1"
                >
                  <option value="">Select State...</option>
                  {countryState.map((state, index) => {
                    return (
                      <option key={index} value={state.isoCode}>
                        {state.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6 mt-3 mobile-mt-2">
                <div className="fw-600 f-16">City</div>
                <select
                  // onChange={handleChange}
                  name="city"
                  className="my-input mt-1"
                  id=""
                  defaultValue={profileData?.address?.city}
                >
                  <option value="">Select City...</option>
                  {stateCity.map((city, index) => {
                    return (
                      <option key={index} value={city.name}>
                        {city.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-md-6 mt-3 mobile-mt-2">
                <div className="fw-600 f-16">Postal Code</div>
                <input
                  className="my-input mt-1"
                  type="text"
                  placeholder="Postal Code"
                  name="pinCode"
                  defaultValue={profileData?.address?.pinCode}
                />
              </div>

              <div className="mt-4">
                <button
                  type="submit"
                  id="profileUpdate-btn"
                  className="coman-btn"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form>

          <div className="b-line-bottom mt-3"></div>

          <form onSubmit={handelChangePassword}>
            <div className="row mt-3">
              <div className="f-20 fw-600">Change Password</div>
              <div className="col-md-6 mt-3">
                <div className="f-16 fw-600 mt-1">Current Password</div>
                <div className="mt-1 input-login rel cursor">
                  <input
                    required
                    name="oldPassword"
                    id="valueremove"
                    className="input-sigin-1"
                    type={correctPass == true ? "text" : "password"}
                    placeholder="Current Password"
                  />

                  <span
                    className="mobile-eye-password"
                    onClick={handelShowpasswordCorrect}
                  >
                    {!correctPass ? (
                      <LuEye color="#191F33" size={20} />
                    ) : (
                      <FaRegEyeSlash color="#191F33" size={20} />
                    )}
                  </span>
                </div>
              </div>

              <div className="col-md-6 mt-3 rel">
                <div className="f-16 fw-600 mt-1">New Password</div>
                <div className="mt-1 input-login cursor">
                  <input
                    required
                    id="valueremove"
                    name="newPassword"
                    className="input-sigin-1"
                    type={show == true ? "text" : "password"}
                    placeholder="New Password"
                  />

                  <span
                    className="mobile-eye-password mobile-eye-password-right"
                    onClick={handelShowpassword}
                  >
                    {!show ? (
                      <LuEye color="#191F33" size={20} />
                    ) : (
                      <FaRegEyeSlash color="#191F33" size={20} />
                    )}
                  </span>
                </div>
              </div>

              <div className="mt-3">
                <button id="password-btn" type="submit" className="coman-btn">
                  Change Password
                </button>
              </div>
            </div>
          </form>

          <div className="b-line-bottom mt-3"></div>

          <div className="row mt-3">
            <div className="f-20 fw-600">Delete Account</div>

            <div className="off-set-4 col-md-8 mt-3">
              <div className="c-gray fw-400 f-16">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                accumsan felis nunc, ut sagittis augue imperdiet quis.
                Vestibulum bibendum ultricies ipsum, id suscipit ligula
                facilisis ac. Praesent ultricies augue metus
              </div>
            </div>

            <div className="mt-3">
              <button className="btn-sign">
                <RiDeleteBin5Line size={20} /> Delete Account
              </button>
            </div>
          </div>
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default UserProfileDetails;
