import React from "react";
import Avatar from "react-avatar";

const UserAvatar = ({ name = "R", url = "", size = "40", className = "" }) => {
  if (url == "" || url.match("s3.amazonaws")) {
    return <Avatar className={className} round name={name} size={size} />;
  }

  return (
    <img
      className="cursor rounded-circle"
      src={url}
      height={size}
      width={size}
      alt="profile"
    />
  );
};

export default UserAvatar;
