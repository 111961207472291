const initialValues = (  {
     userDashboard : {
          message : 0,
          recentOrders : [],
          totalReceivedOrders : 0,
          totalUserAds : 0,
          totalUserOrders : 0,
          totalUserRunningOrders : 0,
     },
} )



export const userReducer = ( state = initialValues , action ) => {

     if (action.type ==  "SET_USER_DETAILS") {
          return{...state,userDashboard : action.value }
     }

     return state

}