import React, { Fragment, useEffect, useState } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";

import { PiNotepadLight } from "react-icons/pi";
import { FaArrowRightLong } from "react-icons/fa6";
import { PiShoppingCartThin } from "react-icons/pi";

import { useDispatch, useSelector } from "react-redux";
import { setUserDashboard } from "../../../store/user/user.action";
import RecentlyOrdersItem from "../../../components/products/post/RecentlyOrdersItem";
import { NavLink, useNavigate } from "react-router-dom";
import { UserDashboardOrdersLoader } from "../../../components/loader/AdsItemLoader";
import { MdProductionQuantityLimits } from "react-icons/md";

import "./user-dashboard.css";
const UserDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRecentlyOrder = useSelector(
    (item) => item.userReducer.userDashboard
  );

  const [loader, setLoader] = useState(false);

  // console.log("userRecentlyOrder",userRecentlyOrder);

  const loadUserDashboard = async () => {
    try {
      setLoader(true);
      dispatch(setUserDashboard());
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  // const handelOrderPageRunning = () => {
  //   navigate(`/user/orders?orders=${"running"}`);
  // };
  const handelOrderPage = () => {
    navigate("/user/orders");
  };
  const handelMyAds = () => {
    navigate("/user/ads");
  };
  useEffect(() => {
    loadUserDashboard();
  }, []);

  return (
    <DefaultDashboard>
      <div className="row">
        <div onClick={handelMyAds} className="col-md-4">
          <div className="bg-user-detail-1 d-flex justify-content-between align-items-center cursor">
            <div>
              <div className="f-32 fw-600">
                {userRecentlyOrder.totalUserAds}
              </div>
              <div className=" fw-400 f-16">Posted Ads</div>
            </div>

            <div className="icon-profile">
              <PiNotepadLight color="#00AAFF" size={35} />
            </div>
          </div>
        </div>

        <div onClick={handelOrderPage} className="col-md-4 mobile-mt-2">
          <div className="bg-user-detail-1 bg-user-detail-3 d-flex justify-content-between align-items-center cursor">
            <div>
              <div className="f-32 fw-600">
                {userRecentlyOrder.totalUserRunningOrders}
              </div>
              <div className=" fw-400 f-16">Running Orders</div>
            </div>

            <div className="icon-profile">
              <MdProductionQuantityLimits className="c-orange" size={35} />
            </div>
          </div>
        </div>

        <div onClick={handelOrderPage} className="col-md-4 mobile-mt-2">
          <div className="bg-user-detail-1 bg-user-detail-2 d-flex justify-content-between align-items-center cursor">
            <div>
              <div className="f-32 fw-600">
                {userRecentlyOrder.totalUserOrders}
              </div>
              <div className=" fw-400 f-16">My Orders</div>
            </div>

            <div className="icon-profile">
              <PiShoppingCartThin color="#27C200" size={35} />
            </div>
          </div>
        </div>
      </div>

      <div className="gap-40 d-flex justify-content-between align-items-center">
        <div className="f-20 fw-600">Recent Orders</div>
        <div className="f-15 fw-400">
          <NavLink to="/user/sell-rent/orders" className="c-orange">
            View All &nbsp; <FaArrowRightLong />
          </NavLink>
        </div>
      </div>

      <div className="gap-25">
        <div className="row">
          {loader ? (
            <UserDashboardOrdersLoader />
          ) : (
            <Fragment>
              {userRecentlyOrder.recentOrders.map((item, index) => {
                if (!item.ad) {
                  return null;
                }
                return (
                  <div className="col-md-4 mobile-mt-3" key={index}>
                    <RecentlyOrdersItem productItem={item} />
                  </div>
                );
              })}
            </Fragment>
          )}

          {loader ? (
            ""
          ) : (
            <Fragment>
              {userRecentlyOrder.recentOrders.length == 0 && (
                <div className="t-center">
                  <h5 className="c-gray mt-4">
                    "0" received orders
                  </h5>
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default UserDashboard;
