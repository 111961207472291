import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaArrowRightLong } from "react-icons/fa6";
import { Country, State, City } from "country-state-city";
import { getAuthUser, showToaster } from "../../../config/common";
import PhoneInput from "react-phone-input-2";
import GoogleMapReact from "google-map-react";
// import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";

// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GoogleAddressAutocomplete from "./map/GoogleAddressAutocomplete";

// Validation Schema
const validationSchema = Yup.object().shape({
  // state: Yup.string().required("State is required"),
  // country: Yup.string().required("Country is required"),
  // address: Yup.string().required("Address is required"),
  // pinCode: Yup.string().required("Postal Code is required"),
});

const DefaultLocation = { lat: 10, lng: 106 };
const DefaultZoom = 10;

// console.log("loginUser", loginUser);

const AdsInfomationAddress = (props) => {
  const {
    handleOnChangeTab,
    // handelOnSubmitPostAdd,
    handleOnSubmitData,
    activeDefaultValue = {},
    isEdit = false,
  } = props;

  const loginUser = getAuthUser();
  // console.log("activeDefaultValue",activeDefaultValue);

  // Initial Values
  const adsInformationInitialValue = {
    phoneNumber:
      activeDefaultValue?.contactDetails?.phoneNumber || loginUser?.phoneNumber,
    emailAddress:
      activeDefaultValue?.contactDetails?.emailAddress || loginUser?.email,

    // country:
    //   activeDefaultValue?.contactDetails?.country ||
    //   loginUser?.address?.country,
    // state:
    //   activeDefaultValue?.contactDetails?.state || loginUser?.address?.state,
    // city: activeDefaultValue?.contactDetails?.city || loginUser?.address?.city,

    // pinCode: activeDefaultValue?.contactDetails?.pinCode || loginUser?.pinCode,
    // address:
    //   activeDefaultValue?.contactDetails?.address ||
    //   loginUser?.address?.address,
  };

  const [countryState, setCountryState] = useState([]);
  const [stateCity, setStateCity] = useState([]);

  const [activeCountry, setActiveCountry] = useState("");

  const [selectedAddress, setSelectedAddress] = useState(false);

  // console.log("selectedAddress",selectedAddress);

  const [marker, setMarker] = useState(false);

  const handleOnChangeCountry = (countyCode) => {
    setActiveCountry(countyCode);
    setCountryState(State.getStatesOfCountry(countyCode));
  };

  const handleOnChangeState = (stateCode) => {
    setStateCity(City.getCitiesOfState(activeCountry, stateCode));
  };

  // const { ref } = usePlacesWidget({
  //   apiKey: "AIzaSyB44pxd0_owXRfMD3I7Ep_F4YRqrd3rXQo",
  //   onPlaceSelected: (place) => console.log("place", place),
  // });

  const handlePlaceSelected = (place) => {
    setSelectedAddress(place);
    // You can access the place details from the 'place' object
  };

  return (
    <div className={`${isEdit ? "p-3" : "p-20"} `}>
      <Formik
        initialValues={adsInformationInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          // if(selectedAddress){
          values = { ...values, ...selectedAddress };
          values.latitude = selectedAddress?.lat;
          values.longitude = selectedAddress?.lng;
          console.log("values", values);
          handleOnSubmitData(values);

          handleOnChangeTab(4);
          // } else{

          // }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          setValues,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="fw-600 f-16">Email Address</div>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.emailAddress}
                  required
                  name="emailAddress"
                  className="my-input mt-1"
                  type="email"
                  placeholder="Email Address"
                />
              </div>
              <div className="col-md-6">
                <div className="fw-600 f-16">Phone Number</div>
                <PhoneInput
                  country={"us"}
                  containerClass="cursor mt-1"
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                  }}
                  onChange={(phone, country) =>
                    setValues({
                      ...values,
                      phoneNumber: phone,
                    })
                  }
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <div className="fw-600 f-16">Address</div>

                {/* <input
                 ref={ref}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="address"
                  value={values.address}
                  placeholder="Address"
                  className="my-input mt-1"
                /> */}

                <GoogleAddressAutocomplete
                  onPlaceSelected={handlePlaceSelected}
                  placeholder="Search for a place"
                  // options={{ types: ["(address)"] }} // Example: Restricting search to cities
                />

                {/* <div className="row mt-4 mobile-mt-2">
                  <div className="col-md-6">
                    <div className="fw-600 f-16">Country</div>
                    <select
                      onChange={(e) => {
                        handleChange(e);
                        handleOnChangeCountry(e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.country}
                      required
                      name="country"adsInformationInitialValue
adsInformationInitialValue
                      className="my-input mt-1"
                      id=""
                    >
                      <option value="">Select Country...</option>
                      {Country.getAllCountries().map((country, index) => {
                        return (
                          <option key={index} value={country.isoCode}>
                            {country.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6 mobile-mt-2">
                    <div required className="fw-600 f-16">
                      State
                    </div>
                    <select
                      onChange={(e) => {
                        handleChange(e);
                        handleOnChangeState(e.target.value);
                      }}
                      onBlur={handleBlur}
                      value={values.state}
                      name="state"
                      required
                      className="my-input mt-1"
                      id=""
                    >
                      <option value="">Select State...</option>
                      {countryState.map((state, index) => {
                        return (
                          <option key={index} value={state.isoCode}>
                            {state.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="col-md-6 mt-4 mobile-mt-2">
                    <div className="fw-600 f-16">City</div>
                    <select
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      name="city"
                      className="my-input mt-1"
                      id=""
                    >
                      <option value="">Select City...</option>
                      {stateCity.map((city, index) => {
                        return (
                          <option key={index} value={city.name}>
                            {city.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6 mt-4 mobile-mt-2">
                    <div className="fw-600 f-16">Postal Code</div>
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // value={values.phoneNumber}
                      required
                      type="text"
                      value={values.pinCode}
                      name="pinCode"
                      className="my-input mt-1"
                      placeholder="Postal Code"
                    />
                  </div>
                </div> */}
              </div>
            </div>

            {isEdit ? (
              <div className="gap-50 t-end">
                <button
                  id="address-loader"
                  type="submit"
                  className="coman-btn ms-3 cursor"
                >
                  Save Changes
                </button>
              </div>
            ) : (
              <div className="gap-50 t-end">
                <button
                  type="button"
                  onClick={() => handleOnChangeTab(2)}
                  className="coman-btn-boder"
                >
                  Previous
                </button>

                <button
                  id="postUpload-btn"
                  type="submit"
                  // disabled={!isValid || !dirty}
                  className="coman-btn ms-3"
                >
                  Next Step &nbsp;
                  <FaArrowRightLong />
                </button>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AdsInfomationAddress;
