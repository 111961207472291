import React, { useState } from "react";
import "./price-range.css";

const PriceRangeSlider = (props) => {
  const [minPrice, setMinPrice] = useState(1);
  const [maxPrice, setMaxPrice] = useState(50000);
  const priceGap = 1;

  const handleMinInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= 0 && value <= maxPrice - priceGap) {
      setMinPrice(value);
    } else if (value > maxPrice - priceGap) {
      setMinPrice(maxPrice - priceGap);
    }
  };

  const handleMaxInputChange = (e) => {
    const value = parseInt(e.target.value);
    if (value <= 100000 && value >= minPrice + priceGap) {
      setMaxPrice(value);
    } else if (value < minPrice + priceGap) {
      setMaxPrice(minPrice + priceGap);
    }
  };

  const handleMinRangeChange = (e) => {
    const value = parseInt(e.target.value);
    if (value <= maxPrice - priceGap) {
      setMinPrice(value);
    }
  };

  const handleMaxRangeChange = (e) => {
    const value = parseInt(e.target.value);
    if (value >= minPrice + priceGap) {
      setMaxPrice(value);
    }
  };

  const handlePriceApply = () => {
    if (props.onPriceChange) {
      props.onPriceChange({
        maxPrice: maxPrice,
        minPrice: minPrice,
      });
    }
  };

  return (
    <div className="d-flex">
      <div className="wrapper">
        <header>
          <div className="f-20 fw-600 c-black">Price Range</div>
          <span className="c-gray fw-400 mt-1">
            Use slider or enter min and max price
          </span>
        </header>
        <div className="price-input">
          <div className="field">
            <span>Min</span>
            <input
              type="number"
              className="input-min"
              value={minPrice}
              onChange={handleMinInputChange}
            />
          </div>
          <div className="separator">-</div>
          <div className="field">
            <span>Max</span>
            <input
              type="number"
              className="input-max"
              value={maxPrice}
              onChange={handleMaxInputChange}
            />
          </div>
        </div>
        <div className="slider">
          <div
            className="progress"
            style={{
              left: (minPrice / 100000) * 100 + "%",
              right: 100 - (maxPrice / 100000) * 100 + "%",
            }}
          ></div>
        </div>
        <div className="range-input">
          <input
            type="range"
            className="range-min"
            min="0"
            max="100000"
            value={minPrice}
            step="100"
            onChange={handleMinRangeChange}
          />
          <input
            type="range"
            className="range-max"
            min="0"
            max="100000"
            value={maxPrice}
            step="100"
            onChange={handleMaxRangeChange}
          />
        </div>

        <button
          className="small-btn-orange w-100 mt-4"
          onClick={handlePriceApply}
        >
          Apply Prices
        </button>
      </div>
    </div>
  );
};

export default PriceRangeSlider;
