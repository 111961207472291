export const categories = [
  {
    category: "Electronics",
    subCategories: [
      "Mobile Phones",
      "Laptops",
      "Cameras",
      "Headphones",
      "Wearable Technology",
      "Televisions",
      "Home Appliances",
      "Gaming Consoles",
    ],
  },
  {
    category: "Fashion",
    subCategories: [
      "Men's Clothing",
      "Women's Clothing",
      "Kids' Clothing",
      "Footwear",
      "Accessories",
      "Jewelry",
      "Bags and Purses",
    ],
  },
  {
    category: "Home and Kitchen",
    subCategories: [
      "Furniture",
      "Home Decor",
      "Kitchen Appliances",
      "Bedding",
      "Bath",
      "Storage and Organization",
      "Lighting",
    ],
  },
  {
    category: "Beauty and Personal Care",
    subCategories: [
      "Skincare",
      "Haircare",
      "Makeup",
      "Fragrances",
      "Personal Hygiene",
      "Tools and Accessories",
    ],
  },
  {
    category: "Sports and Outdoors",
    subCategories: [
      "Exercise and Fitness",
      "Outdoor Gear",
      "Sports Equipment",
      "Cycling",
      "Camping and Hiking",
      "Water Sports",
    ],
  },
  {
    category: "Automotive",
    subCategories: [
      "Car Accessories",
      "Motorcycle Accessories",
      "Car Electronics",
      "Car Parts",
      "Tools and Equipment",
    ],
  },
  {
    category: "Books and Media",
    subCategories: [
      "Books",
      "eBooks",
      "Music",
      "Movies and TV Shows",
      "Video Games",
    ],
  },
  {
    category: "Toys and Games",
    subCategories: [
      "Action Figures",
      "Dolls",
      "Educational Toys",
      "Board Games",
      "Puzzles",
    ],
  },
  {
    category: "Health and Wellness",
    subCategories: [
      "Supplements",
      "Medical Supplies",
      "Fitness Equipment",
      "Nutrition",
    ],
  },
  {
    category: "Groceries and Gourmet Food",
    subCategories: [
      "Fresh Produce",
      "Snacks",
      "Beverages",
      "Condiments and Spices",
      "Organic and Specialty Foods",
    ],
  },
  {
    category: "Office Supplies",
    subCategories: [
      "Office Furniture",
      "Stationery",
      "Printers and Accessories",
      "Office Electronics",
    ],
  },
  {
    category: "Pet Supplies",
    subCategories: ["Pet Food", "Pet Toys", "Pet Health", "Pet Grooming"],
  },
  {
    category: "Baby Products",
    subCategories: [
      "Baby Clothing",
      "Diapers",
      "Baby Food",
      "Baby Gear and Furniture",
    ],
  },
  {
    category: "Tools and Home Improvement",
    subCategories: [
      "Power Tools",
      "Hand Tools",
      "Hardware",
      "Electrical",
      "Plumbing",
    ],
  },
  {
    category: "Travel and Luggage",
    subCategories: [
      "Suitcases",
      "Travel Accessories",
      "Backpacks",
      "Travel Gear",
    ],
  },
];
