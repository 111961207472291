import React from "react";

import { GoStack } from "react-icons/go";

import { formatter } from "../../../config/common";
import { NavLink } from "react-router-dom";
import "./recently-orders.css";

const RecentlyOrdersItem = (props) => {
  const productAdsItem = props.productItem;

  // console.log("productAdsItem", productAdsItem);
  return (
    <NavLink to={`/user/orders/details/${productAdsItem.orderNumber}`}>
      <div className="cursor post-img-shadow ">
        <div>
          <img
            className="img-post"
            src={productAdsItem?.ad?.mainImage}
            alt="image"
          />
        </div>
        <div className="post-img-bg">
          <div className="p-15">
            <div className="f-14 c-gray fw-400">
              <GoStack />{" "}
              <span className="ms-1 mt-1">{productAdsItem?.ad?.category}</span>
            </div>

            <div className="f-16 c-title fw-600 mt-2 one-line-text">
              {productAdsItem?.ad?.title}
            </div>
          </div>

          <div className="b-line-bottom"></div>

          <div className="p-15 flex">
            <div className="fw-600 f-16 c-orange">
              {formatter.format(productAdsItem?.ad?.price)}
            </div>

            {/* <div className="fw-600 f-16">
              <TbDots className="c-gray" size={25} />
            </div> */}
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default RecentlyOrdersItem;
