import React, { Fragment } from "react";
import { TbAlertTriangle } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { CgDanger } from "react-icons/cg";
import { GrInProgress } from "react-icons/gr";
const DocumentVerificationStatus = ({ status, rejectMessage = '' }) => {
  const navigate = useNavigate();

  const handelProfileVerify = () => {
    navigate("/user/profile/verify");
  };

  if (!status) {
    return null
  }

  if (status == "Init") {
    return (
      <Fragment>
        <span>
          <TbAlertTriangle className="c-orange" size={30} />
        </span>
        <span className="ms-2 f-16 fw-400 c-orange">
          Your account is not verified. To add posts for selling or renting
          items, please complete the verification process by submitting your ID
          proof.
          <span
            onClick={handelProfileVerify}
            className="fw-700 text-underline cursor"
          >
            {" "}
            Verify your account here
          </span>
        </span>
      </Fragment>
    );
  }

  if (status == "In Progress" || status == "Pending") {
    return (
      <Fragment>
        <span>
          <GrInProgress className="text-primary" size={24} />
        </span>
        <span className="ms-2 f-16 fw-400 text-primary">
          Your documents & proof are under progress, our team will check your
          documents and verified it, you will be get notification via email.
        </span>
      </Fragment>
    );
  }

  if (status == "Rejected") {
    return (
      <Fragment>
        <span>
          <CgDanger color="red" size={26} />
        </span>
        <span style={{ color: "red" }} className="ms-2 f-16 fw-400">
          Your documents & proof are rejected by our team, please check you
          email for more details or contact us.

          <p>
            <b>Reject Reason</b> : {rejectMessage}
          </p>

        </span>


        <span
            onClick={handelProfileVerify}
            className="fw-700 text-underline cursor"
          >
            {" "}
            Upload your document again.
          </span>
      </Fragment>
    );
  }

  if (status == "Verified") {
    return null;
  }
};

export default DocumentVerificationStatus;
