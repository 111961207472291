import React, { useEffect } from "react";
import Banner from "../../components/header/Banner";
import "./contact.css";
import PostRequest from "../../services/PostRequest";
import { showToaster } from "../../config/common";
import { useNavigate } from "react-router-dom";
import Default from "../../components/default/Default";

const Contact = () => {
  const navigate = useNavigate();

  const handelContactSubmit = async (event) => {
    try {
      event.preventDefault();
      const formData = new FormData(event.target);

      var data = {};

      formData.forEach(function (value, key) {
        data[key] = value;
      });

      const options = {
        showLoader: "contact-btn",
      };
      await PostRequest("/contact-us", data, options);
      showToaster(
        "Thank for contact us,Our team will contact you soon via email "
      );

      navigate("/home");
    } catch (error) {
      showToaster(error.message, "error");
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Default>
      <section>
        <Banner title="Contact" />
      </section>

      <section>
        <div className="container">
          <div className="bg-white-contact">
            <div className="row">
              <div className="col-md-5">
                <div className="contact-info padd-contact-info ">
                  <div className="fw-600 ">
                    <h3>Contact Info</h3>
                  </div>

                  <div className="d-flex mt-4">
                    <div>
                      <img src="/images/PhoneCall.png" alt="image" />
                    </div>

                    <div className="f-16 c-gray ms-3">(226) 791-1310</div>
                  </div>

                  <div className="d-flex mt-3">
                    <div>
                      <img src="/images/Envelope.png" alt="image" />
                    </div>

                    <div className="f-16 c-gray ms-3">support@rentoho.com</div>
                  </div>

                  <div className="d-flex mt-3">
                    <div>
                      <img src="/images/MapPinLine.png" alt="image" />
                    </div>

                    <div className="f-16 c-gray ms-3">
                      2002-505 Talbot St., London, ON N6A 2S6
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <form action="" onSubmit={handelContactSubmit}>
                  <div className="fw-600 padd-contact-message">
                    <h3>Send Message</h3>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mt-3 input-login cursor">
                          <input
                            className="input-sigin-1"
                            type="text"
                            name="name"
                            required
                            placeholder="Full Name"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mt-3 input-login cursor">
                          <input
                            className="input-sigin-1"
                            type="text"
                            required
                            name="email"
                            placeholder="Email adress"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="mt-3 input-login cursor">
                      <input
                        className="input-sigin-1"
                        type="text"
                        name="subject"
                        required
                        placeholder="Subjects"
                      />
                    </div>

                    <div className="mt-3">
                      <textarea
                        className="text-area-input cursor w-100"
                        rows="4"
                        required
                        placeholder="Message"
                        name="message"
                      ></textarea>
                    </div>

                    <button
                      id="contact-btn"
                      className="my-button mt-4 cursor padd-12"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default Contact;
