import React, { useState } from "react";
import { formatter, showToaster } from "../../../config/common";
import PostRequest from "../../../services/PostRequest";
import { AiFillHeart } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { CiHeart } from "react-icons/ci";

const FavoriteItems = (props) => {
  const favorite = props.data;

  // console.log('favorite',favorite);

  const [isFavorite, setIsFavorite] = useState(false);

  const handelFavorite = async () => {
    try {
      const data = {
        ad: favorite.ad._id,
      };

      setIsFavorite(!isFavorite);

      const response = await PostRequest("/favorite/add-remove", data);

      showToaster(response.message);

      props.callback();
    } catch (error) {}
  };

  return (
    <tr>
      <td className="d-flex">
        <div>
          <NavLink
            to={`/ads/${favorite.ad.slug}/${favorite.ad._id}`}
            state={favorite.ad}
          >
            <img
              className="product-image"
              src={favorite.ad?.mainImage}
              alt="image"
              width="50"
            />
          </NavLink>
        </div>
        <div className="fw-600 ms-3 mt-4 mobile-font-15 mobile-table-one-line">
          {favorite.ad?.title}
        </div>
      </td>

      <td>
        <span className="f-16 c-gray-dark">{favorite.ad?.transactionType}</span>
      </td>
      <td className="c-gray-dark  fw-600 mobile-table-favorite-price">
        {favorite.ad?.transactionType == "Rent" ? (
          <span className="mobile-font-14">
            {formatter.format(favorite.ad?.price)}{" "}
            <span className="f-12 c-gray">/ {favorite.ad?.priceType}</span>
          </span>
        ) : (
          <span className="mobile-font-14 ">
            {formatter.format(favorite.ad?.price)}
          </span>
        )}
      </td>

      <td>
        <div className="heart-bg cursor">
          {isFavorite ? (
            <CiHeart size={30} />
          ) : (
            <AiFillHeart onClick={handelFavorite} size={30} />
          )}
        </div>
      </td>
    </tr>
  );
};

export default FavoriteItems;
