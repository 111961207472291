import React, { Fragment, useEffect, useState } from "react";
import UserMiniProfile from "../../../components/user/UserMiniProfile";
import { NavLink } from "react-router-dom";
import { formatter, OrderStatusColor } from "../../../config/common";
import moment from "moment";

const OrdersItems = (props) => {
  const order = props.orderItem;

  // console.log("order", order);

  const daysRemaining = Math.max(
    0,
    moment(order.rentEndDate).diff(moment(), "days")
  );

  return (
    <tr key={order.id}>
      <td className="th-title-50">
        <div className="d-flex rel">
          <NavLink
            to={`/ads/${order.ad.slug}/${order.ad._id}`}
            state={order.ad}
          >
            <img
              className="product-image cursor"
              src={order?.ad?.mainImage}
              alt="image"
              width="50"
            />
          </NavLink>
          {order.orderType == "Rent" && (
            <div className="bg-type">{order.orderType}</div>
          )}
          <div className="ms-3">
            <div className="fw-600 mobile-font-16 mobile-table-one-line">
              {order?.ad.title}
            </div>
            <UserMiniProfile user={order.seller} />
          </div>
        </div>
      </td>

      <td className="c-gray f-13 mobile-table-date-order">
        <span>
          {order.orderType == "Sell" ? (
            <Fragment>
              {moment(order.createdAt).format("MMMM DD, YYYY, h:mm a")}{" "}
            </Fragment>
          ) : (
            <Fragment>
              {moment(order.rentStartDate).format("MMMM DD, YYYY")}{" "}
              <span className="c-black fw-600">-</span>{" "}
              {moment(order.rentEndDate).format("MMMM DD, YYYY")}
              <div className="f-13">({daysRemaining} Days Remain)</div>
            </Fragment>
          )}
        </span>
      </td>
      <td className="c-gray-dark  fw-600 mobile-table-w">
        {formatter.format(order.totalAmount)}{" "}
      </td>
      <td
        className={` mobile-font-15 mobile-table-w ${OrderStatusColor(
          order.status
        )}`}
      >
        {order.status}
      </td>
      <td>
        <NavLink to={`/user/orders/details/${order.orderNumber}`}>
          <button className="btn-orange-outline w-100 f-13 mobile-table-w">
            View order
          </button>
        </NavLink>
      </td>
    </tr>
  );
};

export default OrdersItems;
