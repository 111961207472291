import React, { Fragment, useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { HiQuestionMarkCircle } from "react-icons/hi2";
import "./promotion.css";
import {
  formatter,
  handlePapalPaymentInit,
  handlePaypalPaymentApproved,
  showToaster,
} from "../../../config/common";
import PostRequest from "../../../services/PostRequest";
import { PromotionLoader } from "../../../components/loader/AdsItemLoader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PaypalPaymentMethod from "../../../components/payments/paypal/PaypalPaymentMethod";

const PromotionPlans = (props) => {
  const {
    handleOnChangeTab,
    handelOnSubmitPostAdd,
    isEdit = false,
    activeDefaultValue = {},
    finalData = {},
    transactionType,
  } = props;

  const [promotionPlans, setPromotionPlans] = useState([]);

  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedPlansDuration, setSelectedPlansDuration] = useState({});

  const [isPaymentView, setIsPaymentView] = useState(false);

  const [isPaymentStarted, setIsPaymentStarted] = useState(false);

  const [loader, setLoader] = useState(false);

  const loadPromotionAd = async () => {
    try {
      setLoader(true);
      const response = await PostRequest("/promotion/plans");

      setPromotionPlans(response);
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoader(false);
    }
  };

  const handelPromotionPayment = () => {
    setIsPaymentView(true);
  };

  const handleOnSelectPlan = (plan) => {
    const planClone = [...selectedPlans];
    const index = planClone.findIndex((p) => p._id == plan._id);

    if (index == -1) {
      planClone.push(plan);
    } else {
      planClone.splice(index, 1);
    }

    setSelectedPlans(planClone);
  };

  const handleOnChangeDuration = (plan, duration) => {
    const cloneOfDuration = { ...selectedPlansDuration };
    cloneOfDuration[plan._id] = duration;
    setSelectedPlansDuration(cloneOfDuration);
  };

  const _getTotalAmount = () => {
    var totalAmount = 0;

    selectedPlans.map((plan, index) => {
      var duration = 1;
      if ((selectedPlansDuration[plan._id], index)) {
        duration = +selectedPlansDuration[plan._id];
      }

      totalAmount = totalAmount + duration * plan.perDayPrice;
    });

    return totalAmount;
  };

  const _getPlansWithDuration = () => {
    var nrMaster = [];

    selectedPlans.map((plan, index) => {
      var duration = 1;
      if ((selectedPlansDuration[plan._id], index)) {
        duration = +selectedPlansDuration[plan._id];
      }

      nrMaster.push({ ...plan, duration: duration });
    });

    return nrMaster;
  };

  const onApprove = async (data) => {
    try {
      setIsPaymentStarted(true);

      finalData.transactionType = transactionType;

      const createdAd = await PostRequest("/ad/create", finalData);
      if (!createdAd)
        throw new Error("Oops! Failed to create your ad! Please try again");
      const response = await handlePaypalPaymentApproved(
        {
          ...data,
          ad: createdAd,
          amount: _getTotalAmount(),
          plans: _getPlansWithDuration(),
        },
        "/paypal/promotion/execute-payment"
      );

      if (!response)
        throw new Error(
          "Your ad has been publish successfull! but unable to promote due to payment failed! Please try again."
        );
      setIsPaymentView(false);
      handleOnChangeTab(5);
    } catch (error) {
      showToaster(error.message, "error");
    } finally {
      setIsPaymentStarted(false);
    }
  };

  useEffect(() => {
    loadPromotionAd();
  }, []);

  return (
    <div className={`${isEdit ? "p-3" : "p-20"} `}>
      <Table
        style={{ display: isPaymentView ? "none" : "block" }}
        className="user-ads-list-table"
        hover
        responsive
      >
        <thead>
          <tr>
            <th width="500px">Features</th>
            <th>Duration Time</th>
            <th>Prices</th>
          </tr>
        </thead>
        <tbody>
          {loader ? (
            <PromotionLoader />
          ) : (
            <Fragment>
              {promotionPlans.map((plan, index) => {
                return (
                  <tr key={index}>
                    <td className="th-title-50">
                      <div className="d-flex align-items-center rel">
                        <div className="promotion-check-box">
                          <input
                            onChange={() => handleOnSelectPlan(plan)}
                            type="checkbox"
                            defaultChecked={selectedPlans.find(
                              (p) => p._id == plan._id
                            )}
                            height={100}
                            width={100}
                          />
                        </div>

                        <img
                          className="cursor ms-3 rounded"
                          //     src={order?.plan?.mainImage}
                          src="https://apollo.olx.in/v1/files/1qbd8gvkg2iy1-IN/image;s=300x600;q=60"
                          alt="image"
                          width="60px"
                          height="60px"
                        />

                        <div className="ms-3">
                          <div className="fw-600 mobile-font-16 mobile-table-one-line">
                            {plan.title}{" "}
                            <HiQuestionMarkCircle
                              data-tooltip-id={index.toString()}
                              className="c-orange"
                              size={21}
                            />
                            <ReactTooltip
                              id={index.toString()}
                              place="top"
                              content={plan.description}
                              style={{ width: "200px", zIndex: 111111 - index }}
                            />
                          </div>
                          {/* <UserMiniProfile user={order.seller} /> */}
                        </div>
                      </div>
                    </td>

                    <td>
                      <select
                        onChange={(e) =>
                          handleOnChangeDuration(plan, e.target.value)
                        }
                        className="promotion-select"
                        name=""
                        id=""
                      >
                        <option value="1">1 day</option>
                        <option value="3">3 days</option>
                        <option value="7">7 days</option>
                        <option value="15">15 days</option>
                        <option value="30">30 days</option>
                        <option value="60">60 days</option>
                      </select>
                    </td>

                    <td className="c-gray-dark  fw-600 mobile-table-w">
                      {formatter.format(
                        (selectedPlansDuration[plan._id] || 1) *
                          plan.perDayPrice
                      )}
                    </td>
                  </tr>
                );
              })}
            </Fragment>
          )}

          {/* {loader ? (
            <MyOrdersLoader />
          ) : (
            <Fragment>
              {orders.map((order, index) => {
                return <OrdersItems key={index} orderItem={order} />;
              })}
            </Fragment>
          )} */}

          {/* {loader ? (
            ""
          ) : (
            <Fragment>
              {orders.length == 0 && (
                <tr className="t-center">
                  <td colSpan={5}>
                    <h5 className="c-gray mt-3">"0" orders.</h5>
                  </td>
                </tr>
              )}
            </Fragment>
          )} */}
        </tbody>
      </Table>

      {/* PAYMENT VIEW */}

      {isPaymentStarted && isPaymentView && (
        <div className="text-center">
          <h4> Please Wait </h4>
          <span className="c-gray">
            {" "}
            Verifying Payment, Please don't refresh page or close browser.{" "}
          </span>
        </div>
      )}

      {isPaymentView && !isPaymentStarted && (
        <PaypalPaymentMethod
          createOrder={() =>
            handlePapalPaymentInit(
              { plans: _getPlansWithDuration() },
              "/paypal/promotion/create-payment"
            )
          }
          onApprove={onApprove}
        />
      )}

      {isPaymentView ? (
        <button
          type="button"
          onClick={() => setIsPaymentView(false)}
          className="coman-btn-boder"
        >
          Cancel
        </button>
      ) : (
        <div className="gap-50 t-end">
          <button
            type="button"
            onClick={() => handleOnChangeTab(3)}
            className="coman-btn-boder"
          >
            Previous
          </button>

          {selectedPlans.length != 0 && (
            <button
              type="submit"
              onClick={handelPromotionPayment}
              className="coman-btn ms-3"
            >
              Pay Total {formatter.format(_getTotalAmount())}
            </button>
          )}

          <button
            id="postUpload-btn"
            type="submit"
            onClick={handelOnSubmitPostAdd}
            className="coman-btn ms-3"
          >
            Create a free listing
          </button>
        </div>
      )}
    </div>
  );
};

export default PromotionPlans;
