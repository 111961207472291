import React, { useEffect, useRef, useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { VscSend } from "react-icons/vsc";
import { getAuthUser, toSlug } from "../../../../config/common";
import {
  NavLink,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import MessageItems from "./MessageItems";
import LottieLoader from "../../../../components/lottieloader/LottieLoader";
import moment from "moment";
import PostRequest from "../../../../services/PostRequest";
import { SlArrowLeft } from "react-icons/sl";

const MessageComponents = (props) => {
  const { onMessageSend = () => {} } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  // console.log(searchParams.get("ad"))

  const param = useParams();
  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  const [message, setMessage] = useState([]);
  const [userDetails, setUserDetails] = useState(location.state);

  // console.log("userDetails", userDetails);

  const [newMessage, setNewMessage] = useState("");
  const [isRelatedToAdDone, setIsRelatedToAdDone] = useState(false);

  const loginUser = getAuthUser();

  const handelSubmitMessage = async (event) => {
    try {
      event.preventDefault();

      const formData = new FormData(event.target);

      var data = {};
      formData.forEach(function (value, key) {
        data[key] = value;
      });

      data.receiver = param.id;

      if (!isRelatedToAdDone) {
        data.isRelatedToAd = searchParams.get("ad");
      }

      if (data.isRelatedToAd) {
        setIsRelatedToAdDone(true);
      }

      setMessage((prev) => [
        ...prev,
        {
          _id: "1111",
          sender: {
            _id: "66b304e2a12c872d4cd11287",
            name: loginUser.name,
            id: "66b304e2a12c872d4cd11287",
          },
          receiver: {},
          message: data.message,
          isMedia: false,
          isRead: false,
          isRelatedToAd: null,
          createdAt: moment().toDate(),
          updatedAt: moment().toDate(),
          __v: 0,
        },
      ]);

      await PostRequest("/send-message", data);
      setNewMessage("");
    } catch (error) {
      console.error(error);
    }
  };

  const eraseText = () => {
    setTimeout(() => {
      document.getElementById("output").value = "";
    }, 200);
  };

  const loadUserChats = async () => {
    try {
      if (!param.id) return false;
      const data = {
        user: param.id,
      };

      const response = await PostRequest("/chats", data);

      setMessage(response);
    } catch (error) {
      console.error(error);
    }
  };

  const loadUserDetails = async () => {
    try {
      if (!param.id) return false;
      const data = {
        user: param.id,
      };
      const response = await PostRequest("/user/profile", data);
      setUserDetails(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGoBack = () => {
    navigate("/user/messages");
  };

  useEffect(() => {
    setUserDetails(false);
    setMessage([]);

    loadUserChats();
    loadUserDetails();
  }, [param.id]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [message]);

  if (!userDetails) {
    return <LottieLoader style={{ top: "-20%" }} imageSize={100} size={250} />;
  }

  return (
    <div>
      <div className="b-line-bottom p-15 flex">
        <div className="d-flex align-items-center ">
          <div className="hidden-in-desktop mobile-block">
            <SlArrowLeft onClick={handleGoBack} size={21} />
          </div>

          <NavLink
            to={`/seller/${toSlug(userDetails.name)}/${
              userDetails._id
            }/profile`}
            state={userDetails}
            className="d-flex c-black align-items-center"
          >
            <div className="mobile-ms-2">
              <UserAvatar
                size="50"
                name={userDetails.name}
                url={userDetails.profileImage}
              />
            </div>

            <div className="ms-2">
              <div className="fw-600 f-16">{userDetails.name}</div>
            </div>
          </NavLink>
        </div>
      </div>

      <div
        ref={messageContainerRef}
        style={{ width: "100%", maxHeight: "460px", overflowY: "auto" }}
        className="user-typing-message"
      >
        <div className="row">
          {message.map((message, index) => (
            <div key={index} className="col-md-12">
              <MessageItems message={message} />
            </div>
          ))}
        </div>
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handelSubmitMessage}>
        <div className="typing-message flex">
          <input
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            required
            id="output"
            name="message"
            type="text"
            className="border-none w-75"
            placeholder="Type your message..."
          />

          <button onClick={eraseText} type="submit" className="coman-btn">
            <VscSend />
          </button>
        </div>
      </form>
    </div>
  );
};

export default MessageComponents;
