import React from "react";

const TextCenterComponent = (props) => {
  return (
    <div>
      <div className="container">
        <h3 className="t-center f-600">{props.title}</h3>
      </div>
    </div>
  );
};

export default TextCenterComponent;
