import React from "react";
import Avatar from "react-avatar";
import UserAvatar from "./UserAvatar";
import { NavLink } from "react-router-dom";
import { toSlug } from "../../config/common";

const UserMiniProfile = (props) => {
  const user = props.user;

  // console.log("user", user);

  return (
    <div className="d-flex mt-1 align-items-center">
      <NavLink to={`/seller/${toSlug(user.name)}/${user._id}/profile`}>
        <div className="cursor">
          <UserAvatar url={user.profileImage} name={user.name} size="40" />
        </div>
      </NavLink> 

      <div className="ms-2">
        <span className="f-14">{user?.name}</span>
        <div>
          {/* <button className='btn-orange-outline-small'>Send message</button> */}
          <NavLink to={`/user/messages/${user._id}`}>
            <div className="fw-600 c-orange f-12 cursor">Send Message</div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default UserMiniProfile;
