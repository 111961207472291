import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import PostRequest from "../../services/PostRequest";
import ProductItem from "../products/productItem/ProductItem";

const UrgentAdsComponents = () => {
  const swiperRef = useRef(null);

  const [promoteAds, setPromoteAds] = useState([]);

  const loadPromoteAd = async () => {
    try {
      const data = {
        place: "Urgent Ad",
      };

      const response = await PostRequest("/ads/promote", data);

      setPromoteAds(response);
    } catch (error) {
      console.error("Failed to load promote ads:", error);
    }
  };

  useEffect(() => {
    loadPromoteAd();
  }, []);

  if (promoteAds.length === 0) {
    return null;
  }

  return (
    <div className="container gap-50">
      <h3 className="mt-4 c-orange f-600">Urgent ads</h3>
      <div
        className="row mt-3"
        onMouseEnter={() => swiperRef.current.swiper.autoplay.stop()}
        onMouseLeave={() => swiperRef.current.swiper.autoplay.start()}
      >
        <Swiper
          ref={swiperRef}
          slidesPerView={3}
          spaceBetween={30}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          modules={[Autoplay]}
          className="mySwiper listing-swiper"
        >
          {promoteAds.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <ProductItem product={item} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default UrgentAdsComponents;
