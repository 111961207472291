import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import ChooseLocationFromMap from "./ChooseLocationFromMap";

const GoogleAddressAutocomplete = ({
  onPlaceSelected,
  placeholder,
  options,
  ...rest
}) => {
  const inputRef = useRef(null);


  const [selectCoords, setSelectedCoords] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(false)

  useEffect(() => {
    if (!window.google || !window.google.maps) {
      console.error("Google Maps JavaScript API not loaded");
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();


      const addressComponents = place.address_components;

      const getAddressComponent = (type) => {
        const component = addressComponents.find((component) =>
          component.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      const address = {
        address:
          getAddressComponent("street_address") || getAddressComponent("route"),
        city: getAddressComponent("locality"),
        state: getAddressComponent("administrative_area_level_1"),
        country: getAddressComponent("country"),
        pinCode: getAddressComponent("postal_code"),
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };


      setSelectedAddress(address)

      setSelectedCoords({
          lat: address.lat,
          lng: address.lng,
      })

      if (onPlaceSelected) {
        onPlaceSelected(address);
      }
    });
  }, [options, onPlaceSelected]);


  const handleChangeAddress = (address) => {
     setSelectedAddress(address)
     inputRef.current.value = address.address
     onPlaceSelected(address);
  }


  return (
    <div>
      <input
        className="my-input mt-1"
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        {...rest}
      />
      <ChooseLocationFromMap onChangeAddress={handleChangeAddress} selectCoords={selectCoords}  placeholder={'Choose Location from Map'}/>
    </div>
  );
};

GoogleAddressAutocomplete.propTypes = {
  onPlaceSelected: PropTypes.func.isRequired, // Callback function to handle the selected place
  placeholder: PropTypes.string, // Placeholder text for the input field
  options: PropTypes.object, // Additional options for the autocomplete, like bounds or types
};

GoogleAddressAutocomplete.defaultProps = {
  placeholder: "Enter a location", // Default placeholder text
  options: {}, // Default options for autocomplete
};

export default GoogleAddressAutocomplete;
