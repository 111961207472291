import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import PostRequest from "../../services/PostRequest";
import Banner from "../../components/header/Banner";

import OrderOverview from "./orderSteps/OrderOverview";
import OrderShippingDetails from "./orderSteps/OrderShippingDetails";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { FaStar } from "react-icons/fa";

import OrderPaymentForm from "./orderSteps/OrderPaymentForm";
import { PiCurrencyDollarSimpleFill } from "react-icons/pi";
import { formatter } from "../../config/common";
import OrderConfirmedPlaceholder from "../../components/order/OrderConfirmedPlaceholder";
import SignEContract from "./orderSteps/SignEContract";
import UserAvatar from "../../components/user/UserAvatar";
import LottieLoader from "../../components/lottieloader/LottieLoader";
import Default from "../../components/default/Default";

import "./placeorder.css";

const tabs = [
  {
    title: "Overview",
  },
  {
    title: "Shipping Details",
  },
  {
    title: "Sign e-Contract",
  },
  {
    title: "Payments",
  },
  {
    title: "Place Order",
  },
];

const PlaceOrder = () => {
  const location = useLocation();
  const product = location.state;
  const params = useParams();

  const [orderDetails, setOrderDetails] = useState(product);
  const [activeStep, setActiveStep] = useState("Overview");
  const [finalData, setFinalData] = useState({});

  // console.log("finalData", finalData);

  const [placedOrderId, setPlacedOrderId] = useState("");

  const handleOnChangeTab = (tab) => {
    setActiveStep(tab);
  };

  const loadProductDetails = async () => {
    try {
      const data = {
        ad: params.id,
      };
      const response = await PostRequest("/ads/details", data);
      // console.log("response",response);
      setOrderDetails(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleOnSubmitData = (data) => {
    // console.log("data",data);
    setFinalData({ ...finalData, ...data });
  };

  const handleCreateOrder = async (data) => {
    try {
      const payload = { ...finalData, ...data };
      payload.ad = orderDetails._id;

      // console.log("payload",payload);

      const options = {};
      const response = await PostRequest(
        "/order/place",
        JSON.parse(JSON.stringify(payload)),
        options
      );

      setPlacedOrderId(response.orderNumber);
      // console.log("response", response);
      handleOnChangeTab("Place Order");
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 250);
  }, [activeStep]);

  useEffect(() => {
    loadProductDetails();
  }, []);

  useEffect(() => {
    if (orderDetails && orderDetails?.transactionType == "Sell") {
      setActiveStep("Shipping Details");
    }
  }, [orderDetails]);

  if (!orderDetails) {
    return <LottieLoader />;
  }

  return (
    <Default>
      <section>
        <Banner title={orderDetails?.title} />
      </section>

      <div className="container gap-30">
        <div className="row">
          <div className="col-md-9">
            <div className="bg-user-post-ad">
              <div
                className={`${
                  orderDetails.transactionType === "Sell"
                    ? "d-flex justify-content-around mobile-overflow-scroll"
                    : "d-flex justify-content-between mobile-overflow-scroll"
                }`}
              >
                {tabs.map((tab, index) => {
                  if (orderDetails.transactionType === "Sell" && index === 0) {
                    return null;
                  }

                  if (orderDetails.transactionType === "Sell" && index === 2) {
                    return null;
                  }

                  if (orderDetails.transactionType === "Sell") {
                    index = index - 1;
                  }
                  if (orderDetails.transactionType === "Sell" && index > 1) {
                    index = index - 1;
                  }

                  index++;

                  return (
                    <div key={index}>
                      <div
                        className={`step-box-placeorder d-flex align-items-center ${
                          activeStep === tab.title ? "border-active" : ""
                        }`}
                      >
                        <div className="round-order-step f-32 mobile-font-16 mt-1">
                          {index}
                        </div>
                        <div className="ms-2 mt-1">
                          <div className="f-18 fw-600 mobile-font-14 mobile-fw-700">
                            Steps {index}
                          </div>
                          <div className="f-14 fw-400 c-gray mobile-font-12 mobile-one-line">
                            {tab.title}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="b-line"></div>

              {/* step 01  OrderOverview*/}
              {orderDetails?.transactionType === "Rent" && (
                <div
                  className="p-20"
                  style={{
                    display: activeStep === "Overview" ? "block" : "none",
                  }}
                >
                  <OrderOverview
                    handleOnChangeTab={handleOnChangeTab}
                    handleOnSubmitData={handleOnSubmitData}
                  />
                </div>
              )}

              {/* step 02 OrderShipping-detais*/}

              <div
                className="p-20"
                style={{
                  display: activeStep === "Shipping Details" ? "block" : "none",
                }}
              >
                <OrderShippingDetails
                  orderDetails={orderDetails}
                  handleOnChangeTab={handleOnChangeTab}
                  handleOnSubmitData={handleOnSubmitData}
                />
              </div>

              {/* Sign e-Contract */}
              {orderDetails?.transactionType === "Rent" && (
                <div
                  className="p-20"
                  style={{
                    display:
                      activeStep === "Sign e-Contract" ? "block" : "none",
                  }}
                >
                  <SignEContract
                    handleOnChangeTab={handleOnChangeTab}
                    handleOnSubmitData={handleOnSubmitData}
                  />
                </div>
              )}

              {/* step 3 - Payment */}

              {activeStep === "Payments" && (
                <div className="p-20">
                  <OrderPaymentForm
                    numberOfDays={finalData.daysOfRent || 1}
                    handleOnSubmitData={handleCreateOrder}
                    handleOnChangeTab={handleOnChangeTab}
                    payment={orderDetails}
                  />
                </div>
              )}

              {/* step 4 - Order Confirmed */}

              <div
                style={{
                  display: activeStep === "Place Order" ? "block" : "none",
                }}
              >
                <OrderConfirmedPlaceholder orderNumber={placedOrderId} />
              </div>
            </div>
          </div>
          <div className={`col-md-3 mobile-mt-3`}>
            <div className="order-detail-card f-16 c-black fw-600">
              <div className="d-flex justify-content-between align-items-center">
                <div>Seller Details</div>
                <NavLink to={`/user/messages/${orderDetails.user._id}`}>
                  <button className="btn-orange-outline rounded-pill mobile-btn-sendMessage f-13">
                    <IoChatbubbleEllipsesOutline size={17} /> &nbsp;Send Message
                  </button>
                </NavLink>
              </div>

              <div className="d-flex align-items-center gap-20">
                <UserAvatar
                  url={orderDetails?.user.profileImage}
                  name={orderDetails?.user.name}
                />
                <div className="fw-600 ms-3">
                  {orderDetails?.user.name}
                  <div className="d-flex align-items-center">
                    <FaStar className="c-orange" />
                    <div className="mt-1">
                      <span className="ms-1">
                        {!orderDetails.user.averageRating ? (
                          "0"
                        ) : (
                          <Fragment>
                            {orderDetails?.user?.averageRating}
                          </Fragment>
                        )}
                      </span>
                      <span style={{ color: "gray" }} className="c-gray-dark">
                        ({orderDetails?.user?.totalRating})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="order-detail-card f-16 c-black fw-600 mt-4">
              <div>Order Details</div>

              <div className="mt-2 fw-600">
                <PiCurrencyDollarSimpleFill className="c-orange" size={20} />{" "}
                <span className="ms-1">
                  {formatter.format(orderDetails?.price)}{" "}
                  {orderDetails?.transactionType === "Rent" && (
                    <span className="c-gray f-12">
                      {" "}
                      / {orderDetails?.priceType}
                    </span>
                  )}{" "}
                </span>
              </div>

              <div className="mt-3">
                <img
                  className="border-radius-10"
                  width="100%"
                  src={orderDetails?.mainImage}
                  alt="image"
                />

                <div className="fw-700 f-17 mt-2 ">{orderDetails?.title}</div>
                <div className="mt-2 f-13 c-gray">
                  {orderDetails?.description}
                </div>

                <NavLink
                  to={`/ads/${orderDetails.slug}/${orderDetails._id}`}
                  state={orderDetails}
                >
                  <div className="f-14 mt-2 fw-600 c-orange">View More</div>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Default>
  );
};

export default PlaceOrder;
