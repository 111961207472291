import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { Fragment, useEffect, useState } from "react";
import PostRequest from "../../../services/PostRequest";
import { PaymentFormLoader } from "../../../components/loader/AdsItemLoader";
import { formatter, showToaster } from "../../../config/common";
import { LuLoader2 } from "react-icons/lu";
import PaymentButton from "./PaymentButton";
// const stripePromise = loadStripe(
//   "pk_test_51PfeTaDI5UJvScqL8WUMUAP1mI63f7qSHK40eaH0UcJQdfehNgc5LNPhyAc9dokButtYmmqBPY2Qa8be9ClkmQkM00qPK7jQIL"
// );

const OrderPaymentForm = (props) => {
  const {
    handleOnChangeTab,
    payment,
    handleOnSubmitData,
    numberOfDays = 1,
  } = props;

  // console.log("payment", payment);

  const [clientSecret, setClientSecret] = useState("");
  const [loader, setLoader] = useState(false);

  var totalAmount = 0;

  if (payment.transactionType == "Rent") {
    var perDayAmount = payment.price;
    if (payment.priceType == "Per Month") {
      perDayAmount = payment.price / 30;
    }

    if (payment.priceType == "Per Year") {
      perDayAmount = payment.price / 365;
    }

    // console.log("payment.securityDeposit)", payment.securityDeposit);

    totalAmount = perDayAmount * numberOfDays;
    totalAmount = totalAmount + Number(payment.securityDeposit);
  } else {
    totalAmount = payment.price;
  }

  // const loadPaymentKey = async () => {
  //   try {
  //     const data = {
  //       numberOfDays: numberOfDays,
  //       ad: payment._id,
  //     };

  //     setLoader(true);

  //     const options = {};
  //     const keyResponse = await PostRequest("/make-payment", data, options);
  //     setClientSecret(keyResponse.client_secret);
  //   } catch (error) {
  //     showToaster(error.message, "error")
  //   } finally {
  //     setLoader(false);
  //   }
  // };

  const handelPaymentSuccess = (paymentSuccessResponse) => {
    const data = {
      paymentId: paymentSuccessResponse.id,
    };
    handleOnSubmitData(data);
  };

  useEffect(() => {
    // loadPaymentKey();
  }, []);

  return (
    <div>
      {loader ? (
        <PaymentFormLoader />
      ) : (
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div>
              {payment.transactionType == "Rent" ? (
               
                  <div className="mb-4">
                    <div className="d-flex align-items-center f-16 fw-400 mt-1 justify-content-between">
                      <div>
                        <div>Total Rent Amount</div>
                        <div className="c-gray f-14">
                          ({`${numberOfDays} Days`})
                        </div>
                      </div>

                      <div className="fw-600 f-16">
                        {formatter.format(
                          totalAmount - Number(payment.securityDeposit)
                        )}
                      </div>
                    </div>

                   { Number(payment.securityDeposit) > 0 && ( <div className="d-flex align-items-center f-16 fw-400 mt-2 justify-content-between">
                      <div>
                        <div>Deposit Amount</div>
                        <div className="c-gray f-14">(Refundable)</div>
                      </div>

                      <div className="fw-600 f-16">
                        {formatter.format(Number(payment.securityDeposit))}
                      </div>
                    </div>)}

                    <div className="d-flex align-items-center f-16 fw-400 mt-2 justify-content-between">
                      <div className="fw-600">Total Amount</div>

                      <div className="fw-600 f-16">
                        {formatter.format(totalAmount)}
                      </div>
                    </div>
                  </div>
                
              ) : (
                <Fragment>
                  <div className="d-flex align-items-center f-16 fw-400 mb-4 justify-content-between">
                    <div className="fw-600">Total Amount</div>

                    <div className="fw-600 f-16">
                      {formatter.format(Number(payment.price))}
                    </div>
                  </div>
                </Fragment>
              )}

              <div className="m-auto">
                <PaymentButton
                  onPaymentSuccess={handelPaymentSuccess}
                  totalAmount={totalAmount}
                  payment={payment}
                  numberOfDays={numberOfDays}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PaymentForm = ({
  handleOnChangeTab,
  handleOnSubmitData,
  totalAmount,
  payment,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!stripe || !elements) {
        return;
      }
      setIsLoading(true);
      const confirmPayment = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });
      if (confirmPayment.error) {
        if (
          confirmPayment.error.type === "card_error" ||
          confirmPayment.error.type === "validation_error"
        ) {
          alert(confirmPayment.error.message);
        } else {
          alert(confirmPayment.error.message);
        }
      } else {
        // Payment succeeded, proceed with API call
        const data = {
          paymentId: confirmPayment.paymentIntent.id,
        };
        handleOnSubmitData(data);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div id="payment-element">
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />
          <div className="gap-30 t-end">
            <button
              type="button"
              onClick={() => {
                payment?.transactionType === "Rent"
                  ? handleOnChangeTab("Sign e-Contract")
                  : handleOnChangeTab("Shipping Details");
              }}
              className="coman-btn-boder"
            >
              Previous
            </button>

            <button
              id="orderloader-btn"
              type="submit"
              disabled={isLoading}
              className="coman-btn ms-3"
            >
              {isLoading && <LuLoader2 />} Pay {formatter.format(totalAmount)}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OrderPaymentForm;
