import React, { useState } from "react";
import "./profile-verification.css";
import { Country } from "country-state-city";
import * as Yup from "yup";
import { Formik } from "formik";
import PostRequest from "../../../services/PostRequest";
import { useNavigate } from "react-router-dom";
import { showToaster } from "../../../config/common";

// Validation Schema
const validationSchema = Yup.object().shape({
  country: Yup.string().required("Country is required"),
  documentType: Yup.string().required("DocumentType is required"),
});

// Initial Values
const adsInformationInitialValue = {
  country: "",
  documentType: "",
};

const WebBrowser = () => {
  const navigate = useNavigate();

  const [activeCountry, setActiveCountry] = useState("");

  const [documentType, setDocumentType] = useState("");

  //   console.log("activeCountry", activeCountry);

  const handleOnChangeCountry = (countryCode) => {
    setActiveCountry(countryCode);
  };

  const handelDocumentType = (e) => {
    setDocumentType(e.target.value);
  };

  const handelPersonalInfo = async (event) => {
    try {
      event.preventDefault();
      const options = {
        showLoader: "load-submit",
      };

      const values = {
        country: activeCountry,
        documentType: documentType,
      };

      //  console.log("values", values);

      const response = await PostRequest(
        "/user/documents/add-update",
        values,
        options
      );

      // console.log("response", response);
      showToaster("in success");

      navigate(`/user/document-verification/${response}`);

      //  console.log("response", response);
    } catch (error) {
      console.log("error", error.message);
      showToaster(error.message, "error");
    }
  };

  return (
    <div className="web-using-bg">
      <div className="container">
        <div className="row">
          <div className=" offset-md-4 col-md-4">
            <div className="web-verification-center-menu">
              <h3 className="t-center">ID Verification</h3>

              <form onSubmit={handelPersonalInfo}>
                <div className="gap-30">
                  <label className="f-16 fw-600">issuing Country</label>

                  <select
                    onChange={(e) => {
                      handleOnChangeCountry(e.target.value);
                    }}
                    // onBlur={handleBlur}
                    // value={values.country}
                    name="country"
                    required
                    className="web-browser-select mt-2"
                  >
                    <option value="">Select Country...</option>
                    {Country.getAllCountries().map((country, index) => {
                      return (
                        <option key={index} value={country.isoCode}>
                          {country.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="mt-4 w-100">
                  <label className="f-16 fw-600 w-100">Document Type</label>
                  <select
                    required
                    onChange={handelDocumentType}
                    // onBlur={handleBlur}
                    // value={values.documentType}
                    name="documentType"
                    className="web-browser-select w-100 mt-2"
                  >
                    <option value="">Select Document type...</option>

                    <option value="ID Card">ID Card</option>

                    <option value="Passport">Passport</option>

                    <option value="Green Card">Green Card</option>
                  </select>
                </div>

                <div className="gap-30 w-100 t-center">
                  <button type="submit" id="load-submit" className="my-button">
                    Start Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebBrowser;
