import React from "react";
import NotificationItems from "./NotificationItems";
import "./notification.css";
import { NavLink } from "react-router-dom";

const NotificationComponents = ({ notifications }) => {
  // console.log("notifications",notifications);

  return (
    <div className="notification-bg">
      {notifications.map((notification, index) => {
        return (
          <div key={index}>
            <NotificationItems notification={notification} />
          </div>
        );
      })}

      {/* {
        notifications.length == 0 && <div className="fw-600 f-16 text-center">"0" notifications</div>
      } */}

      {notifications.length == 0 ? (
        <div className="fw-600 f-16 text-center">"0" notifications</div>
      ) : (
        <NavLink to="/user/notifications">
          <div className="t-center c-orange mt-2 me-2 f-14 fw-600 cursor">
            View all
          </div>
        </NavLink>
      )}
    </div>
  );
};

export default NotificationComponents;
