import React from "react";
import UserAvatar from "../user/UserAvatar";
import moment from "moment";
const NotificationItems = ({ notification }) => {
  //   console.log("notification", notification);

  return (
    <div
      className={` ${  
        notification?.isRead ? "notification-item" : "notification-item-noRead"
      }`}
    >
      <div className="d-flex align-items-center w-75">
        <div>
          <UserAvatar url="" name="R" size="40" />
        </div>
        <div className="ms-2 w-75">
          <div className="fw-600 f-16 c-black notification-oneline">
            {notification?.title}
          </div>
          <div
            style={{ marginTop: "-4px" }}
            className="fw-400 f-14 notification-oneline"
          >
            {notification?.description}
          </div>
        </div>
      </div>

      <div>{moment(notification?.createdAt).fromNow(true)}</div>
    </div>
  );
};

export default NotificationItems;
