import React from "react";
import TextCenterComponent from "../text/TextCenterComponent";
import { CiCirclePlus } from "react-icons/ci";
import HowitWorkItems from "./HowitWorkItems";
import { PiUserPlusDuotone } from "react-icons/pi";
import { BsHourglassSplit } from "react-icons/bs";

const HowitWork = (props) => {
  return (
    <div className="work-bg mt-4">
      <TextCenterComponent title="How it Works" />

      <div className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <HowitWorkItems
                description="Sign up to join our community and unlock access to all platform features, ensuring you can start your journey immediately."
                number="01"
                title="Create Account"
                icon={<PiUserPlusDuotone size={40} color="#FF4F4F" />}
              />
            </div>

            <div className="col-md-4">
              <HowitWorkItems
                description="Provide comprehensive details and upload appealing images of your products to attract potential buyers effectively."
                title="Post a Ads"
                number="02"
                icon={<CiCirclePlus size={39} color="#FF4F4F" />}
              />
            </div>

            <div className="col-md-4">
              <HowitWorkItems
                description="Customers discover and purchase  or rent your offerings, with all earnings tracked and payments sent directly to your account seamlessly."
                title="Start Earning"
                number="03"
                icon={<BsHourglassSplit size={34} color="#FF4F4F" />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowitWork;
