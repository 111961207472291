import React, { Fragment, useEffect, useState } from "react";
import { IoFilterOutline, IoSearchOutline } from "react-icons/io5";
import { GoStack } from "react-icons/go";
import { SlLocationPin } from "react-icons/sl";
import { Sheet } from "react-modal-sheet";

import { useNavigate, useParams } from "react-router-dom";

import ProductItem from "./products/productItem/ProductItem";
import PostRequest from "../services/PostRequest";
import { AdsItemCardLoader } from "./loader/AdsItemLoader";

import { categories } from "../data/categories";
import { slugToString, toSlug } from "../config/common";
import PriceRangeSlider from "./price/PriceRangeSlider";
var searchTime;
const ProductsComponents = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(true);

  const [listingProducts, setListingProducts] = useState([]);

  const [activeFilter, setActiveFilter] = useState({});
  const [rangeMile, setRangeMile] = useState(0);

  // console.log("rangeMile", rangeMile);

  // console.log("activeFilter", activeFilter);

  // console.log(Object.keys(activeFilter));

  const [locationRange, setLocationRange] = useState();

  const [isOpen, setOpen] = useState(false);

  const loadListingProducts = async (
    isLoadMore = false,
    hasExtraPayload = {}
  ) => {
    try {
      //

      if (!isLoadMore) {
        setIsLoading(true);
      }

      activeFilter.limit = 24;
      const data = { ...activeFilter, ...hasExtraPayload };

      const options = {
        showLoader: "load-more",
      };

      const response = await PostRequest("/ads/all", data, options);

      if (isLoadMore) {
        setListingProducts((prev) => [...prev, ...response]);
      } else {
        setListingProducts(response);
      }

      if (response.length < 24) {
        setHasLoadMore(false);
      } else {
        setHasLoadMore(true);
      }
    } catch (error) {
    } finally {
      if (!isLoadMore) {
        setIsLoading(false);
      }
    }
  };

  const handelOnChangeSearch = (event) => {
    if (searchTime) {
      clearTimeout(searchTime);
    }

    searchTime = setTimeout(() => {
      setActiveFilter((data) => ({ ...data, search: event.target.value }));
    }, 500);
  };

  const handelOnChangeType = (event) => {
    setActiveFilter((data) => ({
      ...data,
      transactionType: event.target.value == "All" ? null : event.target.value,
    }));
  };

  const handelCitySearch = (event) => {
    if (searchTime) {
      clearTimeout(searchTime);
    }
    setTimeout(() => {
      setActiveFilter((data) => ({ ...data, city: event.target.value }));
    }, 500);
  };

  const loadMoreProducts = () => {
    const data = {
      skip: listingProducts.length,
    };
    loadListingProducts(true, data);
  };

  const handelCategory = (category) => {
    if (category == "") {
      navigate("/ads/listings");
      setActiveFilter((data) => ({ ...data, category: "" }));
      return false;
    }

    navigate(`/ads/listings/${toSlug(category)}`);
    setActiveFilter((data) => ({ ...data, category: category }));
  };

  const handleConditionFilter = (event) => {
    setActiveFilter((data) => ({ ...data, condition: event.target.value }));
  };

  const handleOnPriceChange = (prices) => {
    setActiveFilter((data) => ({ ...data, ...prices }));
  };

  const handelLocationRange = (event) => {
    setLocationRange(event.target.value);
  };

  const handelLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

        setActiveFilter((data) => ({
          ...data,
          radius: locationRange,
          latitude: latitude,
          longitude: longitude,
        }));
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const handelClearFilter = () => {
    navigate("/ads/listings");
  };

  const renderfilter = () => {
    return (
      <div className="category-boder">
        <h5 className="fw-700 c-orange">Type</h5>

        <div className="fw-400 f-15 c-category mt-3">
          <div className="mt-2 d-flex align-items-center hover-orang cursor">
            <div className="redio-size mt-1">
              <input
                onChange={handelOnChangeType}
                value=""
                name="type-filter"
                type="radio"
                className="accent-color with-gap"
                checked={
                  !activeFilter.transactionType ||
                  activeFilter.transactionType == ""
                }
              />
            </div>
            <div
              className={`ms-3  ${
                !activeFilter.transactionType ||
                activeFilter.transactionType == ""
                  ? "c-orange"
                  : ""
              }`}
            >
              All
            </div>
          </div>

          <div className="mt-2 d-flex align-items-center hover-orang cursor">
            <div className="redio-size mt-1">
              <input
                onChange={handelOnChangeType}
                value="Rent"
                name="type-filter"
                type="radio"
                className="accent-color with-gap"
              />
            </div>
            <div
              className={`ms-3  ${
                activeFilter.transactionType == "Rent" ? "c-orange" : ""
              }`}
            >
              Rent
            </div>
          </div>

          <div className="mt-2 d-flex align-items-center hover-orang cursor">
            <div className="redio-size mt-1">
              <input
                onChange={handelOnChangeType}
                value="Sell"
                name="type-filter"
                type="radio"
                className="accent-color with-gap"
              />
            </div>
            <div
              className={`ms-3  ${
                activeFilter.transactionType == "Sell" ? "c-orange" : ""
              }`}
            >
              Buy
            </div>
          </div>
        </div>

        <h5 className="fw-700 c-orange mt-4">Categories</h5>

        <div className="mt-2">
          <div
            onClick={() => handelCategory("")}
            className="f-400 f-16 add-category c-category cursor hover-orang"
          >
            All Categories
          </div>
          {categories.map((item, index) => {
            return (
              <div
                onClick={() => handelCategory(item.category)}
                key={index}
                className={`f-400 f-16 add-category c-category cursor hover-orang ${
                  activeFilter.category == item.category ? "c-orange" : ""
                }`}
              >
                {item.category}
              </div>
            );
          })}
        </div>

        <h5 className="fw-700 c-orange mt-4">CONDITION</h5>

        <div className="fw-400 f-15 c-category mt-3">
          <div className="mt-2 d-flex align-items-center hover-orang cursor">
            <div className="redio-size mt-1">
              <input
                onChange={handleConditionFilter}
                value=""
                name="condition-filter"
                type="radio"
                className="accent-color with-gap"
                checked={
                  !activeFilter.condition || activeFilter.condition == ""
                }
              />
            </div>
            <div
              className={`ms-3  ${
                !activeFilter.condition || activeFilter.condition == ""
                  ? "c-orange"
                  : ""
              }`}
            >
              All
            </div>
          </div>

          <div className="mt-2 d-flex align-items-center hover-orang cursor">
            <div className="redio-size mt-1">
              <input
                onChange={handleConditionFilter}
                value="New"
                name="condition-filter"
                type="radio"
                className="accent-color with-gap"
              />
            </div>
            <div
              className={`ms-3  ${
                activeFilter.condition == "New" ? "c-orange" : ""
              }`}
            >
              New
            </div>
          </div>

          <div className="mt-2 d-flex align-items-center hover-orang cursor">
            <div className="redio-size mt-1">
              <input
                onChange={handleConditionFilter}
                value="Used"
                name="condition-filter"
                type="radio"
                className="accent-color with-gap"
              />
            </div>
            <div
              className={`ms-3  ${
                activeFilter.condition == "Used" ? "c-orange" : ""
              }`}
            >
              Used
            </div>
          </div>
        </div>

        <h5 className="fw-700 c-orange mt-4">LOCATION</h5>

        <div className="mt-4 d-flex justify-content-between f-12 align-items-center">
          <span className="f-16">Distance</span>
          <span>{locationRange} KM</span>
        </div>

        <input
          type="range"
          onChange={handelLocationRange}
          className="form-range"
          min="0"
          max="5000"
          id="customRange2"
        />
        <div className="d-flex justify-content-between align-items-center f-12 text-position">
          <span>0 KM</span>
          <span>5000 KM</span>
        </div>

        <button
          className="small-btn-orange w-100 mt-3"
          onClick={handelLocation}
        >
          Apply Distance
        </button>

        <h5 className="fw-700 c-orange mt-4">PRICES</h5>

        <div className="mt-3">
          <PriceRangeSlider onPriceChange={handleOnPriceChange} />
        </div>
      </div>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params.category]);

  useEffect(() => {
    setOpen(false);

    loadListingProducts(false, { category: slugToString(params.category) });
  }, [activeFilter, params.category]);

  return (
    <section>
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mobile-w-95">
          <div className="listing-search">
            <div className="w-100 cursor">
              <IoSearchOutline size={22} color="#F59449" />
              <input
                type="text"
                onChange={handelOnChangeSearch}
                className="input-search ms-1 f-16 fw-400"
                placeholder="Ads tittle, keyword..."
              />
            </div>

            <div className="w-50 boder-search-left cursor hidden-mobile">
              <SlLocationPin size={20} color="#F59449" />
              <input
                type="text"
                onChange={handelCitySearch}
                className="input-search ms-1 f-16 fw-400"
                placeholder="Search by City"
              />
            </div>
          </div>

          <div className="rel hidden-in-desktop mobile-block cursor">
            <div onClick={() => setOpen(true)}>
              <IoFilterOutline size={30} className="c-orange cursor" />
            </div>

            {Object.keys(activeFilter).length > 1 && (
              <div className="filter-count-bg">
                {Object.keys(activeFilter).length - 1}
              </div>
            )}
          </div>
        </div>

        <div className="row gap-30">
          <div className="col-md-3 hidden-in-mobile">{renderfilter()}</div>
          <div className="col-md-9">
            <div>
              {isLoading ? (
                <AdsItemCardLoader />
              ) : (
                <div className="row">
                  {listingProducts.map((product, index) => {
                    return (
                      <div key={index} className="col-md-4 mb-4">
                        <ProductItem key={index} product={product} />
                      </div>
                    );
                  })}

                  {hasLoadMore && listingProducts.length != 0 && (
                    <div className="t-center">
                      <button
                        id="load-more"
                        onClick={loadMoreProducts}
                        className="my-button  fw-600 mt-3 w-25 loadMore-btn"
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              )}

              {isLoading ? (
                ""
              ) : (
                <Fragment>
                  {listingProducts.length == 0 && (
                    <div className="t-center f-20 c-gray mt-3">
                      No posts found.
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>

      <Sheet isOpen={isOpen} onClose={() => setOpen(false)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Scroller>
            {Object.keys(activeFilter).length > 1 && (
              <div
                onClick={handelClearFilter}
                className="t-end me-4 text-primary"
              >
                Clear Filter?
              </div>
            )}
            {renderfilter()}
          </Sheet.Scroller>
        </Sheet.Container>
        <Sheet.Backdrop />
      </Sheet>
    </section>
  );
};

export default ProductsComponents;
