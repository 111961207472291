import {
     PayPalScriptProvider,
     PayPalButtons,
     usePayPalScriptReducer,
   } from "@paypal/react-paypal-js";
   import PostRequest from "../../../services/PostRequest";
   import { useState } from "react";
   
   // This value is from the props in the UI
   const style = { layout: "vertical" };
   
  
   
   const ButtonWrapper = ({
     showSpinner,
     onApprove,
     createOrder,
   }) => {
   
     
     const [{ isPending }] = usePayPalScriptReducer();
   
     return (
       <>
         {showSpinner && isPending && <div className="spinner" />}
         <PayPalButtons
           style={style}
           disabled={false}
           forceReRender={[style]}
           fundingSource={undefined}
           createOrder={createOrder}
           onApprove={onApprove}
         />
       </>
     );
   };
   
   const clientId =
     "AdUv90l1SecGZYfoHmM7E5VRk2skEKp11_--roKt7aGSGTUlsvCRP5YuuuMVbGft-AtlbCkm3Q61TjTm";
   
const PaypalPaymentMethod = ({ onApprove, createOrder }) => {
   
     return (
     <PayPalScriptProvider
          options={{
            clientId: clientId,
            components: "buttons",
            currency: "USD",
          }}
        >
          <ButtonWrapper
            onApprove={onApprove}
            createOrder={createOrder}
            showSpinner={false}
          />
        </PayPalScriptProvider>
     );
}

export default PaypalPaymentMethod;
   