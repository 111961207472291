import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import PostRequest from "../../../services/PostRequest";
import { useParams } from "react-router-dom";
import { showToaster } from "../../../config/common";

const videoConstraints = {
  facingMode: "environment",
};

const WebCameraComponents = () => {
  const params = useParams();

  //   console.log("params", params);

  const [photo, setPhoto] = useState(false);

  const handelProductPhoto = async () => {
    try {
      const data = {
        order: params.order,
        type: params.type,
        user: params.user,
        photo: photo,
      };

      const options = {
        showLoader: "photo-upload",
      };

      const response = await PostRequest(
        "/order/photos/add-update",
        data,
        options
      );

      showToaster("Photo uploaded successfully");
      setPhoto(false);
    } catch (error) {
      showToaster(error.message, "error");
    }
  };

  return (
    <div>
      {photo && <img height="auto" width="100%" src={photo} alt="image" />}

      {!photo && (
        <Webcam
          height="500px"
          width="100%"
          audio={false}
          screenshotFormat="image/jpeg"
          // className="webcam-style"
          videoConstraints={videoConstraints}
        >
          {!photo &&
            (({ getScreenshot }) => (
              <div className="t-center">
                <button
                  className="my-button mt-4 mobile-mt-3"
                  onClick={() => {
                    const imageSrc = getScreenshot();
                    setPhoto(imageSrc);
                  }}
                >
                  Take Photo
                </button>
              </div>
            ))}
        </Webcam>
      )}

      {photo && (
        <div className="mt-4 mobile-mt-3 t-center">
          <button onClick={() => setPhoto(false)} className="take-capture">
            Retake Photo
          </button>

          <button
            onClick={handelProductPhoto}
            id="photo-upload"
            className="my-button ms-5"
          >
            Upload Photo
          </button>
        </div>
      )}
    </div>
  );
};

export default WebCameraComponents;
