import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./imagegallerycomponent.css";

const ImageGalleryComponent = (props) => {
  // console.log("props", props.data);

  var image = [];

  // console.log("image", image);

  if (!props) {
    return null;
  }

  props.data?.map((img) => {
    image.push({
      original: img,
      thumbnail: img,
    });
  });

  return (
    <div>
      <ImageGallery showNav={false} showThumbnails items={image} />
    </div>
  );
};

export default ImageGalleryComponent;
