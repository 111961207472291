import PostRequest from "../../services/PostRequest";

export const setUserDashboard =  () => {
     return async (dispatch) => {
      try {
 
           const response = await PostRequest('/user/dashboard');
           dispatch( {
                type : "SET_USER_DETAILS",
                value : response
           } )
           
      } catch (error) {
           
      }
     }
 } 