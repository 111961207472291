import React from "react";
import Banner from "../../../components/header/Banner";

import Default from "../../../components/default/Default";
import ProductsComponents from "../../../components/ProductsComponents";


import "./listing-products.css";
import UrgentAdsComponents from "../../../components/promotion_place/UrgentAdsComponents";
const ListingsProducts = () => {
  return (
    <Default>
      <section>
        <Banner title="Ad List" />
      </section>

      <UrgentAdsComponents/>

      <div className="gap-50">
        <ProductsComponents />
      </div>

    </Default>
  );
};

export default ListingsProducts;
