import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import PostRequest from "../../../services/PostRequest";
import { useNavigate, useParams } from "react-router-dom";
const videoConstraints = {
  facingMode: "environment",
};

const UploadDocumentCameraPage = () => {
  const navigate = useNavigate();

  const params = useParams();

  // console.log("params",params);

  const [photo, setPhoto] = useState(false);
  const [isProof, setIsProof] = useState(false);

  const handelUserPhotoUpload = async (event) => {
    try {
      event.preventDefault();
      const options = {
        showLoader: "load-submit",
      };
      const data = {
        [isProof ? "documentProofPhotoBack" : "documentProofPhotoFront"]: photo,
        token: params.token,
      };

      const response = await PostRequest(
        "/user/documents/add-update",
        data,
        options
      );

      if (isProof) {
        navigate("/user/document-upload/thankyou", { state: true });
      } else {
        navigate(`/user/document-verification/proof/${response}`);
        // window.location.reload()
      }
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    if (window.location.href.match("/proof/")) {
      setIsProof(true);
    }

    setPhoto(false);
  }, [params]);

  return (
    <div className="webcam-bg-black">
      <div className="container">
        <div className="row">
          <div className="offset-md-3 col-md-6">
            <div className="center-coman">
              {photo && (
                <img height="auto" width="100%" src={photo} alt="image" />
              )}
              {!photo && (
                <div className="c-white fw-600 f-20 mb-3">
                  Take ID Photo | {!isProof ? "FRONT" : "BACK"}
                </div>
              )}
              {!photo && (
                <Webcam
                  // height="300px"
                  // width="100%"
                  audio={false}
                  screenshotFormat="image/jpeg"
                  className="webcam-style"
                  videoConstraints={videoConstraints}
                >
                  {!photo &&
                    (({ getScreenshot }) => (
                      <div className="t-center">
                        <button
                          className="my-button mt-4 mobile-mt-3"
                          onClick={() => {
                            const imageSrc = getScreenshot();
                            setPhoto(imageSrc);
                          }}
                        >
                          Take Photo
                        </button>
                      </div>
                    ))}
                </Webcam>
              )}

              {!photo && (
                <div className="c-white fw-400 f-14 mt-3">
                  Place the front of your ID within the frame and take a picture
                </div>
              )}

              {photo && (
                <div className="mt-4 mobile-mt-3 ">
                  <button
                    onClick={() => setPhoto(false)}
                    className="take-capture"
                  >
                    Retake Photo
                  </button>

                  <button
                    onClick={handelUserPhotoUpload}
                    id="load-submit"
                    className="my-button ms-5"
                  >
                    Upload Photo
                  </button>
                </div>
              )}

              {/* <div className="camera-frame">
                     <PiCornersOutThin color="white"  size={600}/>
                  </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocumentCameraPage;
