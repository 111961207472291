import React from "react";
import { PiSealCheckDuotone } from "react-icons/pi";
import { NavLink } from "react-router-dom";

const OrderConfirmedPlaceholder = (props) => {
  const { handleOnChangeTab, orderNumber } = props;

  return (
    <div className="success-bg-white mobile-p-30-20">
      <PiSealCheckDuotone color="#27C200" className="mobile-font-45 f-70" />

      <h2 className="mt-3 mobile-font-23">Your Order Placed Successful!</h2>

      <div className="f-16 c-gray t-center mt-3 ">
        Thank you for your order. Once the seller accepts your order, <br /> you
        will receive a notification. You can also track your order status from
        your order history.
      </div>

      <div className="mt-4">
        <NavLink to={`/user/orders/details/${orderNumber}`}>
          <button className="coman-btn ms-3">Track Your Order</button>
        </NavLink>
      </div>
    </div>
  );
};

export default OrderConfirmedPlaceholder;
