import React, { useState } from "react";
import { formatter, PaymentsReceivedColor } from "../../../config/common";
import moment from "moment";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";

const PaymentsReceivedItems = ({ payment }) => {
  const [show, setShow] = useState(false);

  //   console.log("payment", payment);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <tr className="th-title-50">
      <td className="f-15 text-one-line">{moment(payment.createdAt).format("lll")}</td>

      <td className="c-gray text-one-line">
        <NavLink
          to={`/user/orders/details/${payment.order.orderNumber}`}
          className="cursor"
        >
          {payment.order.orderNumber}
        </NavLink>
      </td>

      <td>{formatter.format(payment.totalAmount)}</td>
      <td className="text-one-line">
        <div className="d-flex align-items-center justify-content-between">
          <div className={` ${PaymentsReceivedColor(payment.status)}`}>
            {payment.status}{" "}
          </div>
          <button
            onClick={handleShow}
            className="btn-orange-outline mobile-edit-btn mobile-ms-2"
          >
            View
          </button>
        </div>
      </td>

      {/* Model Payment Information */}

      <Modal show={show} onHide={handleClose} className="model-title-block">
        <Modal.Header closeButton>
          <Modal.Title className="c-orange fw-700">
            Payments Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <div className="c-gray">Order Number : </div>

            <div className="ms-2" style={{ letterSpacing: "2px" }}>
              {payment.order.orderNumber}
            </div>
          </div>
          {payment.order.status == "Canaled" && (
            <div className="d-flex align-items-center mt-2">
              <div className="c-gray">Order Cancel Date : </div>

              <div className="ms-2" style={{ letterSpacing: "2px" }}>
                {moment(payment.order.orderCancelDate).format("DD/MM/YYYY")}
              </div>
            </div>
          )}

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Payment Id : </div>

            <div className="ms-2" style={{ letterSpacing: "1px" }}>
              {payment.order.paymentId}
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Transition Id : </div>

            <div className="ms-2" style={{ letterSpacing: "1px" }}>
              {payment.transactionId}
            </div>
          </div>
          {payment.securityDeposit && (
            <div className="d-flex align-items-center mt-2">
              <div className="c-gray">Security Deposit : </div>

              <div className="ms-2">
                {formatter.format(payment.securityDeposit)}
              </div>
            </div>
          )}

          {payment.itemAmount != "0" && (
            <div className="d-flex align-items-center mt-2">
              <div className="c-gray">Item Amount : </div>

              <div className="ms-2">{formatter.format(payment.itemAmount)}</div>
            </div>
          )}

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Total Amount : </div>

            <div className="ms-2">{formatter.format(payment.totalAmount)}</div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Payment Status : </div>

            <div className="ms-2" style={{ letterSpacing: "1px" }}>
              {payment.status}
            </div>
          </div>

          <div className="d-flex align-items-center mt-2">
            <div className="c-gray">Type : </div>

            <div className="ms-2" style={{ letterSpacing: "1px" }}>
              {payment.type}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </tr>
  );
};

export default PaymentsReceivedItems;
