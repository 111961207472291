import React, { useEffect, useState } from "react";

import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsChatDots } from "react-icons/bs";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getAuthUser } from "../../config/common";
import UserAvatar from "../user/UserAvatar";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import UserSidebar from "../user/UserSidebar";
import { IoNotificationsOutline } from "react-icons/io5";
import PostRequest from "../../services/PostRequest";
import NotificationComponents from "../notification/NotificationComponents";
import "./header.css";

const MyHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [menuShow, setMenuShow] = useState(false);

  const [notificationDrop, setNotificationDrop] = useState(false);

  const [notifications, setNotifications] = useState([]);

  const [notificationCount, setNotificationCount] = useState(0);

  const [userMessageNotificationCount, setUserMessageNotificationCount] =
    useState(0);

  // console.log("notifications", notifications);

  const handleAdPost = () => {
    navigate("/user/post/create");
  };

  const handelChat = () => {
    navigate("/user/messages");
  };

  const handelHome = () => {
    navigate("/home");
  };

  const handelSideMenu = () => {
    setMenuShow(!menuShow);
  };

  useEffect(() => {
    // console.log("HERERE");
    setMenuShow(false);
  }, [location]);

  const loginUser = getAuthUser();

  const loadUserMessageCount = async () => {
    try {
      const response = await PostRequest("/chats/unread/count");

      setUserMessageNotificationCount(response);
    } catch (error) {
      // console.log("error", error.message);
    }
  };

  const loadNotificationCount = async () => {
    try {
      const response = await PostRequest("/notifications");

      // console.log("response", response);

      setNotifications(response);
    } catch (error) {
      // console.log("error", error.message);
    }
  };

  const handelNotificationDropdown = () => {
    setNotificationDrop(!notificationDrop);
  };

  useEffect(() => {
    loadUserMessageCount();
    loadNotificationCount();
  }, []);

  useEffect(() => {
    var filterCount = notifications.filter((item) => item.isRead == false);

    setNotificationCount(filterCount);
  }, [notifications]);

  return (
    <div className="bg-head-2">
      <div className="container">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <img
              onClick={handelHome}
              className="logo-img cursor"
              src="/images/logo.png"
              alt="image_logo"
            />
          </div>

          <div className="w-50">
            <div className="d-flex align-items-center justify-content-between span-bg f-16">
              <span>
                <NavLink to="/home">Home</NavLink>
              </span>

              <span>
                <NavLink to="/ads/listings">Listings</NavLink>
              </span>

              <span>
                <NavLink to="/about">About</NavLink>
              </span>

              <span>
                <NavLink to="/contact">Contact</NavLink>
              </span>

              <span>
                <NavLink to="/how-it-works">How It Works</NavLink>
              </span>

              <button
                onClick={handleAdPost}
                className="my-button height-40 ms-3"
              >
                <AiOutlinePlusCircle /> Post Ad
              </button>
            </div>
          </div>

          <div>
            <div>
              {loginUser ? (
                <div className="d-flex align-items-center">
                  <div className="rel">
                    <div>
                      <IoNotificationsOutline
                        className="cursor"
                        size={25}
                        color="#767E94"
                        onClick={handelNotificationDropdown}
                      />
                    </div>
                    {notificationCount.length != 0 && (
                      <div className="message-count-round">
                        {notificationCount.length}
                      </div>
                    )}

                    {notificationDrop && (
                      <div className="notification-position">
                        <NotificationComponents notifications={notifications} />
                      </div>
                    )}
                  </div>

                  <div className="rel ms-3" onClick={handelChat}>
                    <div>
                      <BsChatDots
                        className="cursor"
                        size={25}
                        color="#767E94"
                      />
                    </div>
                    {userMessageNotificationCount != 0 && (
                      <div className="message-count-round">
                        {userMessageNotificationCount}
                      </div>
                    )}
                  </div>

                  <div className="ms-3">
                    <NavLink to="/user/dashboard">
                      <UserAvatar
                        url={loginUser?.profileImage}
                        name={loginUser?.name}
                        size="37"
                      />
                    </NavLink>
                  </div>

                  <div className="ms-3">
                    {menuShow ? (
                      <IoIosArrowDown
                        className="cursor"
                        onClick={handelSideMenu}
                        size={20}
                      />
                    ) : (
                      <IoIosArrowUp
                        className="cursor"
                        onClick={handelSideMenu}
                        size={20}
                      />
                    )}

                    {menuShow && (
                      <div className="rel">
                        <div className="header-user-menu">
                          <UserSidebar activeUser={loginUser} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <button className="btn-sign">
                  <NavLink to="/login" className="singing">
                    Sign In
                  </NavLink>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyHeader;
