import React, { Fragment, useState } from "react";
import UserAvatar from "../user/UserAvatar";
import { FaStar } from "react-icons/fa6";
import { LuDot } from "react-icons/lu";
import Rating from "react-rating";
import { CiStar } from "react-icons/ci";
import { RiDeleteBinLine } from "react-icons/ri";
import PostRequest from "../../services/PostRequest";
import { getAuthUser, toSlug } from "../../config/common";
import { NavLink } from "react-router-dom";

const SellerReviewComponent = (props) => {
  const review = props.data;

  const loginUser = getAuthUser();

  const [isDeleted, setIsDeleted] = useState(false);

  const handelReviewDelete = async () => {
    try {
      if (!window.confirm("are you sure? you want to remove your review?"))
        return false;

      const data = {
        rating: review._id,
      };
      setIsDeleted(true);
      await PostRequest("/rating/delete", data);
      props.callback();

      props.callBackDetails();
    } catch (error) {
      setIsDeleted(false);
    }
  };

  if (isDeleted) return null;

  return (
    <div>
      <div className="d-flex">
        <div className="mt-1 cursor">
          <NavLink
            to={`/seller/${toSlug(review?.ratedBy.name)}/${
              review.ratedBy._id
            }/profile`}
            state={review.ratedBy}
          >
            <UserAvatar
              className="mobile-avatar"
              name={review.ratedBy.name}
              url={review.ratedBy.profileImage}
              size="65"
            />
          </NavLink>
        </div>
        <div className="ms-3 w-100">
          <div className="d-flex justify-content-between we-100">
            <div className="c-orange">
              <Rating
                readonly
                initialRating={review.rating}
                emptySymbol={<CiStar className="f-20" />}
                fullSymbol={<FaStar className="c-orange f-18" />}
              />
            </div>

            {review.ratedBy._id == loginUser._id && (
              <div className="cursor">
                <RiDeleteBinLine
                  className="cursor f-23 mobile-font-15"
                  onClick={handelReviewDelete}
                  // size={23}
                />
              </div>
            )}
          </div>
          <div>
            <span className="c-black fw-600 f-16">{review.ratedBy.name}</span>

            {review.ad != null && (
              <Fragment>
                <span className="c-gray">
                  s
                  <LuDot />
                </span>
                <span className="f-15 fw-400 c-gray">
                  Ads : 4K UHD LED Smart TV with Chromecast Built-in
                </span>
              </Fragment>
            )}
          </div>
          <div className="fw-400 f-16 mt-2 c-gray mobile-font-12">
            {review.review}
          </div>
        </div>
      </div>

      <div className="b-line-bottom my-3"></div>
    </div>
  );
};

export default SellerReviewComponent;
