import React, { Fragment, useEffect, useState } from "react";
import Banner from "../../components/header/Banner";
import { PiUserCircleGearLight } from "react-icons/pi";
import { GoCreditCard } from "react-icons/go";

import { PiSealQuestionLight } from "react-icons/pi";

// import "./faq.css";
import FrameFaqComponent from "../../components/faq/FrameFaqComponent";
import Accordion from "react-bootstrap/Accordion";
import Default from "../../components/default/Default";
import "./faq.css";
import { isMobile } from "../../config/common";
const Faq = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Default>
      <section>
        <Banner title="Faqs" />
      </section>

      <div className="container gap-70 mobile-mt-3">
        <section>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <FrameFaqComponent
                    icon={<PiSealQuestionLight className="icon-size-mobile" />}
                    title="General"
                  />
                </div>
                <div className="">
                  <FrameFaqComponent
                    icon={
                      <PiUserCircleGearLight className="icon-size-mobile" />
                    }
                    title="Your Account"
                  />
                </div>
                <div className="">
                  <FrameFaqComponent
                    icon={<GoCreditCard className="icon-size-mobile" />}
                    title="Money & payment"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="row gap-50 mobile-mt-0 mb-3">
            <div className="col-md-8 offset-md-2">
              {isMobile() ? (
                <Fragment>
                  <h5 className="c-orange mt-4">
                    <b>Question 1</b>
                  </h5>

                  <div className=" c-gray f-15 mt-2">
                    Ut lectus felis, facilisis nec erat at, pretium pellentesque
                    lectus. Donec sit amet nibh est. Morbi augue libero, pretium
                    vitae nulla ac, malesuada ultricies neque. Donec eget neque
                    erat. Nam dictum justo sit amet placerat consectetur.
                  </div>
                  <div className=" c-gray f-15 mt-2">
                    Maecenas laoreet, ligula vel scelerisque ultricies, lorem
                    urna luctus odio, eget convallis urna erat quis justo. Morbi
                    vel placerat nisl, ut tempor odio. Sed lacinia purus sit
                    amet lorem dictum tincidunt. Nunc placerat egestas vehicula.
                    Fusce eu risus sit amet metus sagittis volutpat vehicula
                    porttitor elit. Sed fringilla purus sed mi scelerisque
                    scelerisque. Donec vel enim ligula. Phasellus vulputate ac
                    dui aliquet dapibus. Suspendisse ut vestibulum tellus, sed
                    gravida arcu. Aliquam erat volutpat.
                  </div>

                  <div className=" c-gray f-15 mt-2">
                    Donec sit amet eleifend tortor. In mattis velit ut nunc
                    luctus ultrices. Nunc aliquet risus eget lorem dignissim, id
                    imperdiet nulla ultricies. Sed euismod bibendum dui, vel
                    faucibus quam tempor at. Etiam sagittis massa in urna
                    sagittis, non viverra nibh placerat.
                  </div>

                  <h5 className="c-orange mt-4">
                    <b>Question 2</b>
                  </h5>

                  <div className=" c-gray f-15 mt-2">
                    Ut lectus felis, facilisis nec erat at, pretium pellentesque
                    lectus. Donec sit amet nibh est. Morbi augue libero, pretium
                    vitae nulla ac, malesuada ultricies neque. Donec eget neque
                    erat. Nam dictum justo sit amet placerat consectetur.
                  </div>
                  <div className=" c-gray f-15 mt-2">
                    Maecenas laoreet, ligula vel scelerisque ultricies, lorem
                    urna luctus odio, eget convallis urna erat quis justo. Morbi
                    vel placerat nisl, ut tempor odio. Sed lacinia purus sit
                    amet lorem dictum tincidunt. Nunc placerat egestas vehicula.
                    Fusce eu risus sit amet metus sagittis volutpat vehicula
                    porttitor elit. Sed fringilla purus sed mi scelerisque
                    scelerisque. Donec vel enim ligula. Phasellus vulputate ac
                    dui aliquet dapibus. Suspendisse ut vestibulum tellus, sed
                    gravida arcu. Aliquam erat volutpat.
                  </div>

                  <div className=" c-gray f-15 mt-2">
                    Donec sit amet eleifend tortor. In mattis velit ut nunc
                    luctus ultrices. Nunc aliquet risus eget lorem dignissim, id
                    imperdiet nulla ultricies. Sed euismod bibendum dui, vel
                    faucibus quam tempor at. Etiam sagittis massa in urna
                    sagittis, non viverra nibh placerat.
                  </div>

                  <h5 className="c-orange mt-4">
                    <b>Question 3</b>
                  </h5>

                  <div className=" c-gray f-15 mt-2">
                    Ut lectus felis, facilisis nec erat at, pretium pellentesque
                    lectus. Donec sit amet nibh est. Morbi augue libero, pretium
                    vitae nulla ac, malesuada ultricies neque. Donec eget neque
                    erat. Nam dictum justo sit amet placerat consectetur.
                  </div>
                  <div className=" c-gray f-15 mt-2">
                    Maecenas laoreet, ligula vel scelerisque ultricies, lorem
                    urna luctus odio, eget convallis urna erat quis justo. Morbi
                    vel placerat nisl, ut tempor odio. Sed lacinia purus sit
                    amet lorem dictum tincidunt. Nunc placerat egestas vehicula.
                    Fusce eu risus sit amet metus sagittis volutpat vehicula
                    porttitor elit. Sed fringilla purus sed mi scelerisque
                    scelerisque. Donec vel enim ligula. Phasellus vulputate ac
                    dui aliquet dapibus. Suspendisse ut vestibulum tellus, sed
                    gravida arcu. Aliquam erat volutpat.
                  </div>

                  <div className=" c-gray f-15 mt-2">
                    Donec sit amet eleifend tortor. In mattis velit ut nunc
                    luctus ultrices. Nunc aliquet risus eget lorem dignissim, id
                    imperdiet nulla ultricies. Sed euismod bibendum dui, vel
                    faucibus quam tempor at. Etiam sagittis massa in urna
                    sagittis, non viverra nibh placerat.
                  </div>
                </Fragment>
              ) : (
                <Accordion defaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="orage-hover">
                      Question 1
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className=" c-gray f-15">
                        Ut lectus felis, facilisis nec erat at, pretium
                        pellentesque lectus. Donec sit amet nibh est. Morbi
                        augue libero, pretium vitae nulla ac, malesuada
                        ultricies neque. Donec eget neque erat. Nam dictum justo
                        sit amet placerat consectetur.
                      </div>
                      <div className=" c-gray f-15 mt-2">
                        Maecenas laoreet, ligula vel scelerisque ultricies,
                        lorem urna luctus odio, eget convallis urna erat quis
                        justo. Morbi vel placerat nisl, ut tempor odio. Sed
                        lacinia purus sit amet lorem dictum tincidunt. Nunc
                        placerat egestas vehicula. Fusce eu risus sit amet metus
                        sagittis volutpat vehicula porttitor elit. Sed fringilla
                        purus sed mi scelerisque scelerisque. Donec vel enim
                        ligula. Phasellus vulputate ac dui aliquet dapibus.
                        Suspendisse ut vestibulum tellus, sed gravida arcu.
                        Aliquam erat volutpat.
                      </div>

                      <div className=" c-gray f-15 mt-2">
                        Donec sit amet eleifend tortor. In mattis velit ut nunc
                        luctus ultrices. Nunc aliquet risus eget lorem
                        dignissim, id imperdiet nulla ultricies. Sed euismod
                        bibendum dui, vel faucibus quam tempor at. Etiam
                        sagittis massa in urna sagittis, non viverra nibh
                        placerat.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="orage-hover">
                      Question 2
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className=" c-gray f-15">
                        Ut lectus felis, facilisis nec erat at, pretium
                        pellentesque lectus. Donec sit amet nibh est. Morbi
                        augue libero, pretium vitae nulla ac, malesuada
                        ultricies neque. Donec eget neque erat. Nam dictum justo
                        sit amet placerat consectetur.
                      </div>
                      <div className=" c-gray f-15 mt-2">
                        Maecenas laoreet, ligula vel scelerisque ultricies,
                        lorem urna luctus odio, eget convallis urna erat quis
                        justo. Morbi vel placerat nisl, ut tempor odio. Sed
                        lacinia purus sit amet lorem dictum tincidunt. Nunc
                        placerat egestas vehicula. Fusce eu risus sit amet metus
                        sagittis volutpat vehicula porttitor elit. Sed fringilla
                        purus sed mi scelerisque scelerisque. Donec vel enim
                        ligula. Phasellus vulputate ac dui aliquet dapibus.
                        Suspendisse ut vestibulum tellus, sed gravida arcu.
                        Aliquam erat volutpat.
                      </div>

                      <div className=" c-gray f-15 mt-2">
                        Donec sit amet eleifend tortor. In mattis velit ut nunc
                        luctus ultrices. Nunc aliquet risus eget lorem
                        dignissim, id imperdiet nulla ultricies. Sed euismod
                        bibendum dui, vel faucibus quam tempor at. Etiam
                        sagittis massa in urna sagittis, non viverra nibh
                        placerat.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="orage-hover">
                      Question 3
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className=" c-gray f-15">
                        Ut lectus felis, facilisis nec erat at, pretium
                        pellentesque lectus. Donec sit amet nibh est. Morbi
                        augue libero, pretium vitae nulla ac, malesuada
                        ultricies neque. Donec eget neque erat. Nam dictum justo
                        sit amet placerat consectetur.
                      </div>
                      <div className=" c-gray f-15 mt-2">
                        Maecenas laoreet, ligula vel scelerisque ultricies,
                        lorem urna luctus odio, eget convallis urna erat quis
                        justo. Morbi vel placerat nisl, ut tempor odio. Sed
                        lacinia purus sit amet lorem dictum tincidunt. Nunc
                        placerat egestas vehicula. Fusce eu risus sit amet metus
                        sagittis volutpat vehicula porttitor elit. Sed fringilla
                        purus sed mi scelerisque scelerisque. Donec vel enim
                        ligula. Phasellus vulputate ac dui aliquet dapibus.
                        Suspendisse ut vestibulum tellus, sed gravida arcu.
                        Aliquam erat volutpat.
                      </div>

                      <div className=" c-gray f-15 mt-2">
                        Donec sit amet eleifend tortor. In mattis velit ut nunc
                        luctus ultrices. Nunc aliquet risus eget lorem
                        dignissim, id imperdiet nulla ultricies. Sed euismod
                        bibendum dui, vel faucibus quam tempor at. Etiam
                        sagittis massa in urna sagittis, non viverra nibh
                        placerat.
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </div>
          </div>
        </section>
      </div>
    </Default>
  );
};

export default Faq;
