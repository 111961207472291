import React from "react";
import "./howitwork.css";
const HowitWorkItems = (props) => {
  return (
    <div>
      <div className="bg-howiswork">
        <div className="d-flex justify-content-between align-items-center">
          <div>{props.icon}</div>
          <div style={{ color: "#EBEEF7" }} className="f-50 fw-400">
            {props.number}
          </div>
        </div>

        <div className="f-24 fw-600">{props.title}</div>

        <div className="f-15 c-gray f-400 mt-2">{props.description}</div>
      </div>
    </div>
  );
};

export default HowitWorkItems;
