import { toast } from "react-toastify";
import PostRequest from "../services/PostRequest";

export const showToaster = (message, type = "success") => {
  toast(message, {
    type: type,
  });
};

export const getAuthUser = () => {
  try {
    return JSON.parse(window.localStorage.getItem("auth"));
  } catch (error) {
    return false;
  }
};

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const OrderStatusColor = (status) => {
  //   console.log("status", status);

  switch (status) {
    case "Pending":
      return "text-info";

    case "Running":
    case "Completed":
      return "text-success";

    case "Accepted":
      return "text-primary";

    case "Canceled":
      return "text-danger";

    default:
      return "text-warning";
  }
};

export const PaymentsReceivedColor = (status) => {
  switch (status) {
    case "Pending":
      return "text-info";

    case "Withdrawal":
    case "Refunded":
      return "text-success";

    case "Failed":
      return "text-danger";

    default:
      return "text-warning";
  }
};

export const toSlug = (str = "") => {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

export const slugToString = (slug = "") => {
  var words = slug.split("-");

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(" ");
};

export const isMobile = () => {
  return Math.min(window.screen.width, window.screen.height) < 768;
};

export const QRCodeGenerator = (qrToken) => {
  return `${
    window.location.origin.match("localhost")
      ? "http://192.168.1.12:3000"
      : window.location.origin
  }/user/profile/verify/country/${qrToken}`;
};

export const QRCodeGeneratorForOrder = (order, type) => {
  const loginUser = getAuthUser();

  return `${
    window.location.origin.match("localhost")
      ? "http://192.168.1.12:3000"
      : window.location.origin
  }/user/order/media/upload/${
    loginUser ? loginUser._id : "X"
  }/${order}/${type}`;
};

export const handlePapalPaymentInit = async (
  data,
  url = "/paypal/create-payment"
) => {
  try {
    const response = await PostRequest(url, data);
    if (!response)
      throw new Error("Oops! failed to make payment! Please try again");

    var token = "";
    var links = response.links;
    for (var i = 0; i < links.length; i++) {
      if (links[i].rel === "approval_url") {
        token = links[i].href.split("EC-", 2)[1];
      }
    }
    return token;
  } catch (error) {
    return false;
  }
};

export const handlePaypalPaymentApproved = async (
  data,
  url = "/paypal/execute-payment"
) => {
  try {
    const response = await PostRequest(url, data);
    return response;
  } catch (error) {
  } finally {
  }
};
