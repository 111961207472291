import React from "react";
import "./faqcomp.css";

const FrameFaqComponent = (props) => {
  return (
    <div className="frame-bg">
      <div className="c-orange color-white-hover f-40 icon-size-mobile">
        {props.icon}
      </div>

      <div className="f-18 fw-600 mt-3 text-chnage mobile-font">
        {props.title}
      </div>
    </div>
  );
};

export default FrameFaqComponent;
