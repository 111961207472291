import React from "react";
import "./banner.css";

const Banner = (props) => {
  return (
    <div className="banner-main">
      <div className="text-center">
        <div className="f-32 text-white mobile-font-16">{props.title}</div>
        <div className="f-16 mt-2 text-white">
          <span className="text-opacity-6">Home &nbsp; /</span>
        
          <span className="ms-2 text-opacity-6">{props.title}</span>
      
        </div>
      </div>
    </div>
  );
};

export default Banner;
