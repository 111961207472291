import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const DocumentUploadComponents = () => {
  const location = useLocation();

  if (!location.state) return null;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2 center-coman">
          <div className="t-center">
            <img
              height="auto"
              width="60%"
              src="/images/thankyou.jpg"
              alt="document"
            />

            <p>
              Your documents has been uploaded successfully. Rentoho team will
              review it and notify via mail for future update. <br />
              Your Document is secured.
            </p>

            <NavLink to="/home">
              <button className="my-button mt-3">Back To Home</button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentUploadComponents;
