import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Formik } from "formik";
import * as Yup from "yup";
import { City, Country, State } from "country-state-city";
import { getAuthUser, showToaster } from "../../../config/common";
import PhoneInput from "react-phone-input-2";
import GoogleAddressAutocomplete from "../../user/form/map/GoogleAddressAutocomplete";

// Validation Schema
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string().required("Phone Number is required"),
  email: Yup.string().required("Email is required"),
  name: Yup.string().required("Name is required"),
});

const loginUser = getAuthUser();

// Initial Values
const adsInformationInitialValue = {
  city: loginUser?.address.city,
  state: loginUser?.address.state,
  country: loginUser?.address.country,
  phoneNumber: loginUser?.phoneNumber,
  email: loginUser?.email,
  pinCode: loginUser?.address.pinCode,
  name: loginUser?.name,
  address: loginUser?.address?.address,
};

const OrderShippingDetails = (props) => {
  const { handleOnChangeTab, handleOnSubmitData, orderDetails } = props;
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [countryState, setCountryState] = useState([]);
  const [stateCity, setStateCity] = useState([]);
  const [activeCountry, setActiveCountry] = useState("");

  const handleOnChangeCountry = (countyCode) => {
    setActiveCountry(countyCode);
    setCountryState(State.getStatesOfCountry(countyCode));
  };

  const handleOnChangeState = (stateCode) => {
    setStateCity(City.getCitiesOfState(activeCountry, stateCode));
  };

  const handlePlaceSelected = (place) => {
    setSelectedAddress(place);
  };

  return (
    <div>
      <Formik
        initialValues={adsInformationInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          values = { ...values, ...selectedAddress };
          values.latitude = selectedAddress?.lat;
          values.longitude = selectedAddress?.lng;
          handleOnSubmitData({
            shippingAddress: values,
          });
          orderDetails?.transactionType === "Rent"
            ? handleOnChangeTab("Sign e-Contract")
            : handleOnChangeTab("Payments");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
          setValues,
          setFieldValue,
          setFieldTouched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="fw-600 f-16 mt-3">Name</div>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  name="name"
                  className="my-input mt-1"
                  type="text"
                  placeholder="Name"
                  value={values.name}
                  defaultValue={loginUser?.name}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="fw-600 f-16 mt-3">Email</div>
                <input
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  name="email"
                  defaultValue={loginUser?.email}
                  className="my-input mt-1"
                  type="email"
                  placeholder="Email Address"
                  value={values.email}
                />
              </div>
              <div className="col-md-6">
                <div className="fw-600 f-16 mt-3">Phone Number</div>
                <PhoneInput
                  country={"us"}
                  containerClass="mt-1 cursor"
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                  }}
                  onChange={(phone, country) => {
                    setFieldValue("phoneNumber", phone);
                    setFieldTouched("phoneNumber", true, true);
                  }}
                  onBlur={handleBlur}
                  value={values.phoneNumber}
                />

                {touched.phoneNumber && errors.phoneNumber && (
                  <span className="text-danger f-12">{errors.phoneNumber}</span>
                )}
              </div>
            </div>

            <div className="fw-600 f-16 mt-3">Address</div>
            <GoogleAddressAutocomplete
              onPlaceSelected={handlePlaceSelected}
              placeholder="Search for a place"
            />

            <div className="gap-40 t-end">
              {orderDetails?.transactionType === "Rent" && (
                <button
                  type="button"
                  onClick={() => handleOnChangeTab("Overview")}
                  className="coman-btn-boder"
                >
                  Previous
                </button>
              )}

              <button
                id="postUpload-btn"
                type="submit"
                disabled={!isValid}
                className="coman-btn ms-3"
              >
                Next Step &nbsp;
                <FaArrowRightLong />
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default OrderShippingDetails;
