import React from "react";
import Lottie from "react-lottie";

const LottieLoader = (props) => {
  const { size = 400, imageSize = 165, style = {} } = props;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require("./lottie-animetion.json"),
  };

  return (
    <div className="rel" style={style}>
      <div
        style={{
          position: "absolute",
          top: `calc(50vh - ${size / 2}px)`,
          left: `calc(50% - ${size / 2}px)`,
        }}
      >
        <Lottie options={defaultOptions} height={size} width={size} />
      </div>

      <div
        style={{
          position: "absolute",
          top: `calc(50vh - ${imageSize / 2 + 4}px)`,
          left: `calc(50% - ${imageSize / 2}px)`,
        }}
      >
        <img
          height={imageSize}
          width={imageSize}
          className="rounded-circle mt-1 cursor"
          src="/images/logo-round.png"
          alt="image"
        />
      </div>
    </div>
  );
};

export default LottieLoader;
