import React, { useEffect, useState } from "react";
import DefaultDashboard from "../../../components/dashboard/DefaultDashboard";
import Conversions from "./chatcomponents/Conversions";
import MessageComponents from "./chatcomponents/MessageComponents";

import "./user-chats.css";
import { useParams } from "react-router-dom";

const UserChats = () => {
  const params = useParams();
  console.log(params.id);

  const [isNewUser, setIsNewUser] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 300);
  }, []);

  return (
    <DefaultDashboard title="Messages">
      <div className="bg-user-message d-flex">
        <div
          className={`w-35 border-right-message mobile-w-100 ${
            params.id == undefined ? "" : "hidden-in-mobile"
          }`}
        >
          <Conversions isNewUser={isNewUser} />
        </div>
        <div
          className={`w-65 rel mobile-w-100 ${
            params.id != undefined ? "" : "hidden-in-mobile"
          }`}
        >
          <MessageComponents onMessageSend={setIsNewUser} />
        </div>
      </div>
    </DefaultDashboard>
  );
};

export default UserChats;
