import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Formik } from "formik";
import * as Yup from "yup";

// Validation Schema
const validationSchema = Yup.object().shape({
  1: Yup.string().required("required"),
  2: Yup.string().required("required"),
  3: Yup.string().required("required"),
  4: Yup.string().required("required"),
  5: Yup.string().required("required"),
  6: Yup.string().required("required"),
  7: Yup.string().required("required"),
  8: Yup.string().required("required"),
  9: Yup.string().required("required"),
});

// Initial Values
const adsInformationInitialValue = {
  1: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "",
  7: "",
  8: "",
  9: "",
};

const SignEContract = (props) => {
  const { handleOnChangeTab, handleOnSubmitData } = props;
  return (
    <section>
      <Formik
        initialValues={adsInformationInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleOnSubmitData(values);
          handleOnChangeTab("Payments");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="container gap-35 mobile-mt-0">
              <div className="col-md-12">
                <div className="f-20 fw-600 c-orange">
                  General Rentoho Terms and Conditions​
                </div>

                <div className="f-18 fw-600 c-black mt-3">
                  <div className="f-16 fw-600 mt-1">
                    Hereinafter, the party renting the items will be referred to
                    as the ‘renter’ and the owner of the item will be referred
                    to as the ‘owner’.
                  </div>
                </div>

                <div className="f-18 fw-600 c-black mt-4">
                  <ul>
                    <li className="f-16 fw-400 mt-1">
                      Renter and owner are responsible for the transport of
                      items​.
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Rentoho is not responsible for transportation of items
                      being exchanged​.
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      There must be no delay in item exchange once transaction
                      has started​.
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Renter will not be charged until both parties confirm
                      exchange of item(s)
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      owner will not be compensated until both parties confirm
                      exchange of item(s)
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Both parties must confirm exchange of item(s)on the
                      Rentoho platform
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Renter and owner must agree to a reasonable security
                      deposit amount, which reflects the existing value of the
                      item(s) in the user specific term(s) section in the
                      ”E-contract”
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Renter is responsible for keeping item(s) safe and in good
                      condition
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      In case of damage, owner can claim full or partial deposit
                      amount
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Rentoho is not liable for any damages or losses that occur
                      to item(s)
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Users(renter owner) must strictly abide to the user
                      specific terms negotiated and mentioned in the
                      “E-contract”
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Renter and owner must set user specific terms for every
                      transaction and exchange of items to protect their
                      interests
                    </li>
                    <li className="f-16 fw-400 mt-1">
                      Please be aware that by using the Rentoho platform, you
                      acknowledge and agree that all transactions and exchanges
                      are conducted at your own risk. Rentoho facilitates these
                      interactions but does not assume responsibility for the
                      behavior or agreements of individual users.
                    </li>
                  </ul>
                </div>

                <div className="f-20 fw-600 c-orange">Owner specific terms</div>
                <div className="f-18 fw-600 c-black mt-3">
                  <div className="f-16 fw-600 mt-1">
                    Select the agree to these terms to proceed with the
                    transaction.
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      required
                      className="input-checkbox cursor"
                      type="checkbox"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name="9"
                      value={values["9"]}
                    />
                    <span className="f-14 ms-2 cursor">
                      Return the item on time and undamaged
                    </span>
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      required
                      className="input-checkbox cursor"
                      type="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="1"
                      value={values["1"]}
                    />
                    <span className="f-14 ms-2 cursor">
                      Proper maintenance during rental period
                    </span>
                  </div>
                </div>

                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      name="2"
                      value={values["2"]}
                      className="input-checkbox cursor"
                      type="checkbox"
                    />
                    <span className="f-14 ms-2 cursor">
                      Clean and sanitize the item before returning
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="3"
                      value={values["3"]}
                      required
                      className="input-checkbox cursor"
                      type="checkbox"
                    />
                    <span className="f-14 ms-2 cursor">
                      Ensure the item is fully functional before returning
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      name="4"
                      className="input-checkbox cursor"
                      type="checkbox"
                      value={values["4"]}
                    />
                    <span className="f-14 ms-2 cursor">
                      No unauthorized modifications
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      name="5"
                      className="input-checkbox cursor"
                      type="checkbox"
                      value={values["5"]}
                    />
                    <span className="f-14 ms-2 cursor">
                      Safe storage when not in use
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      name="6"
                      value={values["6"]}
                      className="input-checkbox cursor"
                      type="checkbox"
                    />
                    <span className="f-14 ms-2 cursor">
                      Proper handling during transit
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      name="7"
                      value={values["7"]}
                      className="input-checkbox cursor"
                      type="checkbox"
                    />
                    <span className="f-14 ms-2 cursor">
                      Guarantee item return at the agreed time
                    </span>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center mt-3 f-14 fw-400">
                    <input
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values["8"]}
                      name="8"
                      className="input-checkbox cursor"
                      type="checkbox"
                    />
                    <span className="f-14 ms-2 cursor">
                      Clear and prompt communication
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="gap-30 t-end">
              <button
                type="button"
                onClick={() => handleOnChangeTab("Shipping Details")}
                className="coman-btn-boder"
              >
                Previous
              </button>

              <button
                id="btn-order1"
                type="submit"
                disabled={!isValid || !dirty}
                className="coman-btn ms-3"
              >
                Next Step &nbsp;
                <FaArrowRightLong />
              </button>
            </div>
          </form>
        )}
      </Formik>
    </section>
  );
};

export default SignEContract;
