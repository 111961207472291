import React, { useEffect, useState } from "react";

import TextCenterComponent from "../components/text/TextCenterComponent";
import RentohoSwiper from "../components/swiper/RentohoSwiper";
import HowitWork from "../components/user/HowitWork";
import FeatureComponent from "../components/feature/FeatureComponent";
import Default from "../components/default/Default";
import ProductsComponents from "../components/ProductsComponents";
import "./home.css";
import HomeGalleryAds from "../components/promotion_place/HomeGalleryAds";

const Home = () => {
  // const loginUser = getAuthUser();

  // const [isLoading, setIsLoading] = useState(false);

  // const [adsListingData, setAdsListingData] = useState([]);

  // const loadAdsListings = async () => {
  //   try {
  //     setIsLoading(true);

  //     const data = {
  //       limit: 8,
  //     };
  //     const response = await PostRequest("/ads/all", data);

  //     // console.log('response',response);
  //     setAdsListingData(response);
  //   } catch (error) {
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    window.scrollTo(0, 0);
    // loadAdsListings();
  }, []);

  return (
    <Default>
      <section>
        <RentohoSwiper />
      </section>

      <HomeGalleryAds />

      <section>
        <div className="container">
          <h3 className="mt-3 c-orange f-600">Fresh Recommended ads</h3>
        </div>
      </section>

      <section>
        <ProductsComponents />
        {/* <div className="container mt-4">
          <div className="row">
            {adsListingData.map((product, index) => {
              return (
                <div className="col-md-3 mb-4" key={index}>
                  <ProductItem product={product} />
                </div>
              );
            })}
          </div>

          <div className="t-center mt-4">
            <NavLink to={"/ads/listings"}>
              <button className="my-button fw-600">
                View All Ads <HiArrowRight />
              </button>
            </NavLink>
          </div>
        </div> */}
      </section>

      <section>
        <HowitWork />
      </section>

      <section>
        <div className="bg-orang marketPlace-main">
          <div className="container">
            <div className="t-center w-75 m-auto">
              <div className="f-40 w-75 m-auto marketPlace-title">
                Explore Rentoho-Your MarketPlace Hub
              </div>

              <div className="mt-4">
                Discover the simplicity of buying, selling, and renting all in
                one place with Rentoho. Our platform ensures a seamless and
                convenient online classifieds experience, tailored to meet all
                your marketplace needs efficiently
              </div>
            </div>
          </div>

          <div className="container d-flex align-items-center justify-content-center">
            <img className="img-cloth" src="/images/cloth.jpeg" alt="image" />
            <img className="img-cloth" src="/images/cloth.jpeg" alt="image" />
          </div>
        </div>
      </section>

      <section>
        <div className="container mt-5">
          <TextCenterComponent title="Explore Rentoho's Key Features" />

          <div className="row mt-4">
            <div className="offset-md-2 col-md-8">
              <FeatureComponent />
            </div>
          </div>
        </div>
      </section>
    </Default>
  );
};

export default Home;
