import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdHelp } from "react-icons/md";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
import { categories } from "../../../data/categories";
const AdsInformationForm = (props) => {
  const {
    handleOnChangeTab,
    handleOnSubmitData,
    transactionType = "Sell",
    activeDefaultValue = {},
    isEdit = false,
  } = props;
  // console.log("activeDefaultValue",activeDefaultValue);

  // Validation Schema
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    price: Yup.string().required("Price is required"),
    category: Yup.string().required("Category is required"),
  });

  const [activeCategory, setActiveCategory] = useState({});

  // console.log("activeCategory",activeCategory);

  // Initial Values
  const adsInformationInitialValue = {
    title: activeDefaultValue.title || "",
    category: activeDefaultValue.category || activeCategory.subCategories,
    subCategory: activeDefaultValue.subCategory || "Yes",
    condition: activeDefaultValue.condition || "New",
    price: activeDefaultValue.price || "",
    priceType: activeDefaultValue.priceType || "Per Day",
    isNegotiable: activeDefaultValue.isNegotiable || "yes",
    securityDeposit: activeDefaultValue.securityDeposit || "",
  };

  const handelActiveCategory = (value) => {
    // console.log("value",value);

    const category = categories.find((category) => category.category == value);
    if (category) {
      setActiveCategory(category);
    } else {
      setActiveCategory({});
    }
  };

  useEffect(() => {
    if (Object.keys(activeDefaultValue).length != 0) {
      handelActiveCategory(activeDefaultValue.category);
    }
  }, [activeDefaultValue]);

  return (
    <div className={`${isEdit == true ? "p-3" : "p-20"}`}>
      <Formik
        initialValues={adsInformationInitialValue}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (!isEdit) {
            handleOnChangeTab(2);
          }
          handleOnSubmitData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          dirty,
        }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <div className="fw-600 f-16">Title</div>
              <input
                className="my-input mt-1"
                type="text"
                placeholder="Title"
                name="title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.title}
              />
              {errors.title && touched.title && (
                <span className="text-danger f-12">{errors.title}</span>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                <div>
                  <div className="fw-600 f-16">Category</div>
                  <select
                    required
                    name="category"
                    className="my-input mt-1"
                    onChange={(e) => {
                      handleChange(e);
                      handelActiveCategory(e.target.value);
                    }}
                    onBlur={handleBlur}
                    value={values.category}
                  >
                    <option value="">Select..</option>
                    {categories.map((category, index) => {
                      return (
                        <option key={index} value={category.category}>
                          {category.category}
                        </option>
                      );
                    })}
                    <option value="Other">Other</option>
                  </select>
                  {errors.category && touched.category && (
                    <span className="text-danger f-12">{errors.category}</span>
                  )}
                </div>
              </div>
              <div className="col-md-4 mobile-mt-2">
                <div>
                  <div className="fw-600 f-16">Sub Category</div>
                  <select
                    name="subCategory"
                    className="my-input mt-1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.subCategory}
                  >
                    {activeCategory.subCategories?.map((subCategory, index) => {
                      return (
                        <option key={index} value={subCategory}>
                          {subCategory}
                        </option>
                      );
                    })}
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-4 mobile-mt-2">
                <div className="fw-600 f-16">Condition</div>
                <select
                  required
                  name="condition"
                  className="my-input mt-1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.condition}
                >
                  <option value="New">New</option>
                  <option value="Used">Used</option>
                </select>
                <span className="text-danger f-12">{errors.condition}</span>
              </div>
            </div>

            <div className="row mt-3 mobile-mt-2">
              <div className="col-md-8">
                {transactionType === "Sell" && (
                  <div>
                    <div className="fw-600 f-16">Price (USD)</div>
                    <input
                      className="my-input mt-1"
                      type="number"
                      name="price"
                      placeholder="Pick a good price - what would you pay?"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.price}
                    />
                    {errors.price && touched.price && (
                      <span className="text-danger f-12">{errors.price}</span>
                    )}
                  </div>
                )}

                {transactionType === "Rent" && (
                  <div className="row">
                    <div className="col-md-4">
                      <div>
                        <div className="fw-600 f-16">Rent (USD)</div>
                        <input
                          className="my-input mt-1"
                          type="number"
                          name="price"
                          placeholder="Pick a good amount - what would you pay?"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.price}
                        />
                        {errors.price && touched.price && (
                          <span className="text-danger f-12">
                            {errors.price}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 mobile-mt-2">
                      <div>
                        <div className="fw-600 f-16">Rent Type</div>
                        <select
                          required
                          name="priceType"
                          className="my-input mt-1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.priceType}
                        >
                          <option value="Per Day">Per Day</option>
                          <option value="Per Month">Per Month</option>
                        </select>
                        {errors.priceType && touched.priceType && (
                          <span className="text-danger f-12">
                            {errors.priceType}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4 mobile-mt-2">
                      <div>
                        <div className="fw-600 f-16">
                          Security Deposit{" "}
                          <MdHelp
                            data-tooltip-id="my-tooltip"
                            className="cursor"
                          />{" "}
                        </div>

                        <Tooltip id="my-tooltip" />

                        <ReactTooltip
                          id="my-tooltip"
                          place="top"
                          content="Ensure the security deposit amount is accurately reflective of the item's value"
                          style={{ width: "200px" }}
                        />

                        <input
                          className="my-input mt-1"
                          type="number"
                          name="securityDeposit"
                          placeholder="Ensure deposit amount reflects the value of the item"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.securityDeposit}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="col-md-4 mobile-mt-2">
                <div>
                  <div required className="fw-600 f-16">
                    Negotiable
                  </div>
                  <select
                    name="isNegotiable"
                    className="my-input mt-1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.isNegotiable}
                  >
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.isNegotiable && touched.isNegotiable && (
                    <span className="text-danger f-12">
                      {errors.isNegotiable}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="gap-30">
              {/* BUTTON BASED ON ADD OR EDIT */}

              {isEdit ? (
                <div className="t-end mobile-flex">
                  <button
                    type="submit"
                    id="ads-loader"
                    className="coman-btn  mobile-font-15 cursor"
                  >
                    Save Changes
                  </button>
                </div>
              ) : (
                <div className="t-end mobile-flex">
                  {/* <button className="coman-btn-boder mobile-font-15">View posting rules</button> */}
                  <button
                    type="submit"
                    disabled={!isValid || !dirty}
                    className="coman-btn ms-3 mobile-font-15"
                  >
                    Next Step &nbsp;
                    <FaArrowRightLong />
                  </button>
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AdsInformationForm;
